import { getTenantOutstandingColumns, getTenantOutstandingRows } from "./tenant-outstanding-statement";
import { getTenantOutstanding } from "@/api/finance/reportApi";
import {
  DynamicReport
} from "@/components/dynamic";
import { formatApiPayloadDate } from "@/utils/helpers";

const tenantOutstandingFilters = {
  TenantName: "*",
  TenantNumber: "*",
  InvoiceNumber: "*",
  ComponentNumber: "*",
  ContractNumber: "*",
  TransactionDate: formatApiPayloadDate(new Date()),
  DueDate: formatApiPayloadDate(new Date())
};

const tenantOutstandingFilterOptions = [
  { key: "tenantName", apiKey: "TenantName", keyType: "label" },
  { key: "componentNumber", apiKey: "ComponentNumber", keyType: "number" },
  { key: "contractNumber", apiKey: "ContractNumber", keyType: "label" },
  { key: "tenantNumber", apiKey: "TenantNumber", keyType: "label" },
  { key: "invoiceNumber", apiKey: "InvoiceNumber", keyType: "string" },
  { key: "transactionDate", apiKey: "TransactionDate", keyType: "date" },
  { key: "dueDate", apiKey: "DueDate", keyType: "date" },
];

export default function ReportTenantOutstanding({ activeCompany }) {
  return (
    <DynamicReport
      reportName="tenant-outstanding"
      activeCompany={activeCompany}
      columnsDataProvider={getTenantOutstandingColumns}
      rowsDataProvider={getTenantOutstandingRows}
      fetchData={getTenantOutstanding}
      exportUrl="reports/export-query-report/tenant-outstanding"
      filtersInitialState={tenantOutstandingFilters}
      filterOptions={tenantOutstandingFilterOptions}
      showDateFilter
      showDateRangeFilter={false}
      orderBy="TenantNumber"
    />
  );
}
