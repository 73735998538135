import { useState, useRef, useEffect } from "react";

import { BoxedContent } from "@/components/common";
import { DynamicFormContainer } from "@/components/dynamic";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";

import { useCompanyAccount } from "@/hooks";

function AssetClassForm() {
  const ref = useRef(null);
  const [initialState, setInitialState] = useState({});

  const defaultAccounts = useCompanyAccount({
    params: {
      includeCompanyId: true,
      isLinkedWithRecord: false
    }
  });

  useEffect(() => {
    if (defaultAccounts && !initialState.areAccountsSelected) {
      const {
        fixedAsset, depreciation, accumulatedDepreciation, fixedAssetSale,
      } =
        defaultAccounts;

      setInitialState((prevState) => ({
        ...prevState,
        areAccountsSelected: true,
        fixedAssetAccount: fixedAsset,
        depreciationAccount: depreciation,
        accumulatedDepreciationAccount: accumulatedDepreciation,
        saleAccount: fixedAssetSale,
      }));
    }
  }, [defaultAccounts]);

  return (
    <BoxedContent>
      <DynamicFormContainer
        ref={ref}
        initialData={initialState}
        objectName={dynamicObjectMap.get("AssetClassObjectName")}
        showHeader
        onStateChange={onStateChange}
      />
    </BoxedContent>
  );
}

export default AssetClassForm;
