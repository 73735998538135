import { getAgingColumns, getAgingRows } from "./aging-statement";
import { getCustomerAging } from "@/api/finance/reportApi";
import {
  DynamicReport
} from "@/components/dynamic";
import { formatApiPayloadDate } from "@/utils/helpers";

const agingFilters = {
  BuildingNumber: "*",
  TenantNumber: "*",
  TenantId: "*",
  ComponentNumber: "*",
  ContractNumber: "*",
  TransactionDate: formatApiPayloadDate(new Date()),
  DueDate: formatApiPayloadDate(new Date())
};

const agingFilterOptions = [
  { key: "buildingNumber", apiKey: "BuildingNumber", keyType: "label" },
  { key: "tenantNumber", apiKey: "TenantNumber", keyType: "label" },
  { key: "tenantId", apiKey: "TenantId" },
  { key: "componentNumber", apiKey: "ComponentNumber", keyType: "label" },
  { key: "contractNumber", apiKey: "ContractNumber", keyType: "label" },
  { key: "transactionDate", apiKey: "TransactionDate", keyType: "date" },
  { key: "dueDate", apiKey: "DueDate", keyType: "date" },
];

export default function RentRollReport({ activeCompany }) {
  return (
    <DynamicReport
      reportName="customer-aging"
      isPaginated
      activeCompany={activeCompany}
      columnsDataProvider={getAgingColumns}
      rowsDataProvider={getAgingRows}
      fetchData={getCustomerAging}
      exportUrl="reports/export-query-report/customer-aging"
      filtersInitialState={agingFilters}
      showDateFilter
      showDateRangeFilter={false}
      filterOptions={agingFilterOptions}
      orderBy="TenantName"
    />
  );
}
