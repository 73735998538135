import { useState, useRef, useEffect } from "react";
import { addYears, differenceInDays } from "date-fns";
import { useParams, useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { isEmpty, kebabCase } from "lodash";

import { BoxedContent } from "@/components/common";
import { DynamicFormContainer } from "@/components/dynamic";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import {
  getDynamicObjectRecordById,
} from "@/api/dynamic/dynamicObjectNameApi";

import { useCompanyAccount, useTaskRedirect } from "@/hooks";

const formatFixedAssetRequest = (fixedAssetRequest) => {
  const { id, number, purchaseInvoice } = fixedAssetRequest;
  const { id: purchaseInvoiceId, number: purchaseInvoiceNumber, supplier } = purchaseInvoice;
  const { id: supplierId, name } = supplier;

  const data = {
    fixedAssetCreationRequest: {
      label: number,
      value: id
    },
    purchaseInvoice: {
      label: purchaseInvoiceNumber,
      value: purchaseInvoiceId
    },
    supplier: {
      label: name,
      value: supplierId
    }
  };

  return data;
};

function ItemForm() {
  const { id } = useParams();
  const ref = useRef(null);
  const [state, setState] = useState({});
  const { redirect } = useTaskRedirect();
  const [searchParameters] = useSearchParams();
  const fixedAssetCreationRequestId = searchParameters.get("fixedAssetCreationRequest");

  const defaultAccounts = useCompanyAccount({
    params: {
      includeCompanyId: true,
      isLinkedWithRecord: false
    }
  });

  const setFixedAssetAccounts = () => {
    const {
      fixedAssetClearing,
      fixedAssetSaleClearing,
      fixedAssetSurplusRevaluation,
      fixedAssetRevaluation,
      fixedAssetControl,
      fixedAssetReclassification,
      fixedAssetInterComponentTransfer
    } = defaultAccounts;
    const fixedAssetAccounts = {
      fixedAssetClearingAccount: fixedAssetClearing,
      fixedAssetSaleClearingAccount: fixedAssetSaleClearing,
      revaluationAccount: fixedAssetRevaluation,
      surplusRevaluationAccount: fixedAssetSurplusRevaluation,
      fixedAssetControlAccount: fixedAssetControl,
      fixedAssetReclassificationAccount: fixedAssetReclassification,
      fixedAssetInterComponentTransferAccount: fixedAssetInterComponentTransfer
    };

    ref.current.setFormState(fixedAssetAccounts);
  };

  const setItemAccounts = () => {
    const { materialIncome, materialExpense } = defaultAccounts;

    ref.current.setFormValue({
      incomeAccount: materialIncome,
      expenseAccount: materialExpense,
    });
  };

  useEffect(() => {
    if (state.itemType && state.itemType.value === "Asset" && defaultAccounts) {
      setFixedAssetAccounts();
    }
  }, [state.itemType, defaultAccounts]);

  useEffect(() => {
    if (state.itemType && state.itemType.value !== "Asset" && defaultAccounts) {
      setItemAccounts();
    }
  }, [state.itemType, defaultAccounts]);

  const { data: fixedAssetRequestData } = useQuery(
    [kebabCase(dynamicObjectMap.get("FixedAssetCreationRequestObjectName")), fixedAssetCreationRequestId],
    () =>
      getDynamicObjectRecordById(
        kebabCase(dynamicObjectMap.get("FixedAssetCreationRequestObjectName")),
        fixedAssetCreationRequestId
      ),
    {
      enabled: Boolean(fixedAssetCreationRequestId),
    }
  );

  const { data: itemData } = useQuery(
    [kebabCase(dynamicObjectMap.get("ItemObjectName")), id],
    () =>
      getDynamicObjectRecordById(
        kebabCase(dynamicObjectMap.get("ItemObjectName")),
        id
      ),
    {
      enabled: Boolean(id),
    }
  );

  useEffect(() => {
    if (fixedAssetRequestData) {
      const formattedData = formatFixedAssetRequest(fixedAssetRequestData);
      setState((prevState) => ({
        ...prevState,
        ...formattedData
      }));
    }
  }, [fixedAssetRequestData]);

  const setUsefulLife = (usefulLife) => {
    const { usefulLife: prevUsefulLife, remainingLife: remainingLifeInDays } = itemData;

    if (usefulLife === prevUsefulLife) return;

    const oldRetirementDate = addYears(new Date(), prevUsefulLife);
    const oldFullRemainingLife = differenceInDays(oldRetirementDate, new Date());
    const depreciatedLife = oldFullRemainingLife - remainingLifeInDays;

    const newRetirementDate = addYears(new Date(), usefulLife);
    const newFullRemainingLife = differenceInDays(newRetirementDate, new Date());
    const remainingLife = newFullRemainingLife - depreciatedLife;

    ref.current.setFormValue("remainingLife", remainingLife);
  };

  const setFixedAssetClassAccounts = (assetClass) => {
    const {
      depreciationAccount,
      accumulatedDepreciationAccount,
      saleAccount,
      fixedAssetAccount,
      itemType,
      unitOfMeasure,
      usefulLife,
      isDepreciated,
    } = assetClass;
    const fixedAssetData = {
      depreciationAccount: {
        label: depreciationAccount.name,
        value: depreciationAccount.id,
      },
      accumulatedDepreciationAccount: {
        label: accumulatedDepreciationAccount.name,
        value: accumulatedDepreciationAccount.id,
      },
      saleAccount: {
        label: saleAccount.name,
        value: saleAccount.id,
      },
      fixedAssetAccount: {
        label: fixedAssetAccount.name,
        value: fixedAssetAccount.id,
      },
      usefulLife,
      isDepreciated,
    };

    if (!isEmpty(unitOfMeasure)) {
      fixedAssetData.unitOfMeasure = {
        label: unitOfMeasure.name,
        value: unitOfMeasure.id,
      };
    }

    if (itemType) {
      fixedAssetData.itemType = {
        label: itemType,
        value: itemType,
      };
    }

    if (!id) {
      const todayDate = new Date();
      const retirementDate = addYears(todayDate, usefulLife);
      const remainingLife = differenceInDays(retirementDate, todayDate);

      fixedAssetData.remainingLife = remainingLife;
    } else {
      setUsefulLife(usefulLife);
    }

    ref.current.setFormState(fixedAssetData);
  };

  const setTotalCost = (additionalCost) => {
    if (!additionalCost) {
      return;
    }

    const totalCost = additionalCost.reduce((prevValue, { cost }) => prevValue + Number(cost), 0);

    ref.current.setFormValue("totalCost", totalCost);
  };

  const onStateChange = (key, value) => {
    switch (key) {
      case "itemType":
        setState((prevState) => ({
          ...prevState,
          [key]: value,
        }));
        break;

      case "assetClass":
        if (value) {
          setFixedAssetClassAccounts(value);
        }
        break;

      case "additionalCost":
        setTotalCost(value);
        break;

      case "usefulLife":
        setUsefulLife(value);
        break;

      default:
        break;
    }
  };

  const handleSuccess = (recordId) => {
    redirect(-1, {
      recordId,
      success: true,
    });
  };

  return (
    <BoxedContent>
      <DynamicFormContainer
        ref={ref}
        objectName={dynamicObjectMap.get("ItemObjectName")}
        showHeader
        onStateChange={onStateChange}
        onSuccess={handleSuccess}
        navigate={false}
      />
    </BoxedContent>
  );
}

export default ItemForm;
