import { useState } from "react";
import {
  DynamicReport
} from "@/components/dynamic";
import { getSalesReconciliationRows, getSalesReconciliationColumns } from "./sales-reconciliation-statement";
import { getSalesReconciliation } from "@/api/finance/reportApi";
import { formatNumber } from "@/utils/helpers";

const salesReconciliationFilters = {
  RecordType: "*",
};

const salesReconciliationFilterOptions = [
  { key: "recordType", apiKey: "RecordType", keyType: "label" },
];

export default function ReportSalesReconciliation({ activeCompany }) {
  const [reportCardState, setReportCardState] = useState(null);

  const handleDataLoaded = (data) => {
    const sums = data.reduce((acc, item) => {
      acc.vATValue += item.vATValue;
      return acc;
    }, {
      vATValue: 0,
    });

    const reportCardData = {
      size: 1,
      dataChunks: 1,
      data: [
        {
          titleValue: `${formatNumber(sums?.vATValue || 0)}`,
          titleColor: "success",
          subtitle: "VAT Value (AED)",
          subtitleColor: "primary",
        }
      ],
    };
    setReportCardState(reportCardData);
  };

  return (
    <DynamicReport
      reportName="sales-reconciliation"
      activeCompany={activeCompany}
      columnsDataProvider={getSalesReconciliationColumns}
      rowsDataProvider={getSalesReconciliationRows}
      fetchData={getSalesReconciliation}
      exportUrl="reports/export-query-report/sales-reconciliation"
      filtersInitialState={salesReconciliationFilters}
      filterOptions={salesReconciliationFilterOptions}
      orderBy="CompanyName"
      onDataLoaded={handleDataLoaded}
      reportCardData={reportCardState}
    />
  );
}
