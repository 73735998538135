import { useRef, useReducer, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import {
  Button,
  Form,
  FormControl,
  ReactSelect,
  Input,
  Label,
  Loader,
} from "@hydra/atom/components";
import { selectPermission } from "@/store/userSlice";
import { formatApiPayloadDate } from "@/utils/helpers";
import showToast from "@/utils/toast/helpers";
import { getRoles } from "@/api/user/authApi";

import {
  BoxedContent, Header, FormLeftHeader, CustomizedDatePicker
} from "@/components/common";
import {
  accountingPeriodFormReducer,
  initialState,
  setFormValue,
  setInitialState,
  statusOptions,
} from "@/reducers/finance/accountingPeriodFormReducer";
import {
  createAccountingPeriod,
  updateAccountingPeriod,
  getAccountingPeriodById,
} from "@/api/finance/accountingPeriodApi";
import { selectActiveCompany } from "@/store/appSlice";
import { colorStyles } from "@/utils/select/constants";
import { CustomActionDropdown } from "@/components/dynamic";
import {
  deleteLedgerAccount
} from "@/api/finance/ledgerAccountApi";
import { getInterimPeriodTableColumns, getInterimPeriodTableData } from "@/components/finance/accounting-period/interimPeriodTableData";
import InterimPeriodTable from "@/components/finance/accounting-period/InterimPeriodTable";

const validationMessages = {
  required: {
    required: "This field is required",
  },
};

function ActionCell({
  deleteMutation, id
}) {
  const actions = [
    {
      title: "Delete",
      onClick: () => { deleteMutation.mutate(id); },
      icon: "trash-icon"
    }
  ];
  return (
    <div className="action-cell">
      <CustomActionDropdown
        actions={actions}
        trigger={(
          <div className="action-dropdown-trigger">
            <span className="text">Actions</span>
            <span className="material-icons-outlined">expand_more</span>
          </div>
        )}
        testId="Add-Accounting-Period-Button"
      />
    </div>
  );
}
const prepareData = (state) => {
  const data = { ...state };
  const {
    company, startDate, endDate, status
  } = state;

  data.companyId = company.value;
  data.startDate = formatApiPayloadDate(new Date(startDate));
  data.endDate = formatApiPayloadDate(new Date(endDate));
  data.status = status.value;

  return data;
};

const prepareState = (data) => {
  const state = { ...data };

  state.status = statusOptions.find((s) => s.value === data.status);
  state.interimPeriods = state.interimPeriods.map((item) => ({ ...item, accountingId: state.id }));

  if (data.status === "Closed") {
    state.isClosed = true;
  } else {
    state.isClosed = false;
  }

  return state;
};

export default function AddAccountingPeriod() {
  const activeCompany = useSelector(selectActiveCompany);
  const [state, dispatch] = useReducer(accountingPeriodFormReducer, initialState);
  const queryClient = useQueryClient();
  const userRoles = getRoles();
  const isAdmin = userRoles.includes("admin");
  const deletePermission = useSelector(selectPermission({ parent: "Model", scope: "AccountingPeriod", action: "Delete" }));

  const submitButtonRef = useRef(null);
  const { id } = useParams();
  const navigate = useNavigate();

  const { data, isInitialLoading } = useQuery(
    ["accounting-period-by-id", id],
    () => getAccountingPeriodById(id),
    {
      enabled: id !== undefined,
    }
  );

  const deleteMutation = useMutation(deleteLedgerAccount, {
    onError: () => {
      showToast("Could not delete. Try again!", "error");
    },
    onSuccess: () => {
      showToast("Deleted successfully", "success");
      queryClient.invalidateQueries({
        queryKey: ["ledger-account"],
      });
    },
  });

  useEffect(() => {
    if (id && data) {
      const stateData = prepareState(data?.data);
      dispatch(setInitialState(stateData));
    }
  }, [data]);

  const saveMutation = useMutation(createAccountingPeriod, {
    onError: () => {
      showToast("Could not create. Try again!", "error");
    },
    onSuccess: () => {
      showToast("Created successfully", "success");
      navigate(-1);
    },
  });

  const updateMutation = useMutation(
    ({ id: recordId, data: recordData }) => updateAccountingPeriod(recordId, recordData),
    {
      onError: () => {
        showToast("Could not update. Try again!", "error");
      },
      onSuccess: () => {
        showToast("Updated successfully", "success");
        navigate(-1);
      },
    }
  );

  const handleCancel = () => {
    dispatch(setInitialState(initialState));
    navigate(-1);
  };

  const handleSave = () => {
    submitButtonRef.current.click();
  };

  const handleSubmit = async () => {
    const recordData = prepareData({
      ...state,
      division: "Months",
      company: {
        label: activeCompany.name,
        value: activeCompany.id,
      },
    });

    if (id) {
      updateMutation.mutate({
        id,
        data: recordData,
      });
      return;
    }

    saveMutation.mutate(recordData);
  };

  if (id && isInitialLoading) {
    return <Loader />;
  }

  return (
    <BoxedContent className="gl-account-form">
      <Header
        showBreadcrumb
        leftContent={<h1>{`${id ? "Edit" : "Create"} Accounting Period`}</h1>}
      />
      <Form
        onSubmit={handleSubmit}
        key="company-form"
        className="dynamic-object-form"
        shouldScrollOnError
      >
        <div className="row">
          <div className="col-md-3">
            <FormLeftHeader
              title="Name"
              subtitle="Provide Name of Accounting Period"
              icon="scan-icon"
            />
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-6">
                <FormControl>
                  <Label htmlFor="name" label="Name*" />
                  <Input
                    name="name"
                    placeholder="Name"
                    value={state.name}
                    onChange={(value) => dispatch(setFormValue("name", value))}
                    rules="required"
                    messages={validationMessages.required}
                    testId="AccountingPeriod-Name-Input"
                  />
                </FormControl>
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div className="hr" />
          </div>

          <div className="col-md-3">
            <FormLeftHeader
              title="Accounting Period Details"
              subtitle="Provide Accounting Period Details"
              icon="scan-icon"
            />
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-3">
                <FormControl>
                  <Label htmlFor="startDate" label="Start Date*" />
                  <CustomizedDatePicker
                    value={state.startDate}
                    onChange={(value) => dispatch(setFormValue("startDate", value))}
                    required
                    testId="AccountingPeriod-StartDate-DatePicker"
                  />
                </FormControl>
              </div>
              <div className="col-md-3">
                <FormControl>
                  <Label htmlFor="endDate" label="End Date*" />
                  <CustomizedDatePicker
                    value={state.endDate}
                    onChange={(value) => dispatch(setFormValue("endDate", value))}
                    required
                    testId="AccountingPeriod-EndDate-DatePicker"
                  />
                </FormControl>
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div className="hr" />
          </div>
          <div className="col-md-3">
            <FormLeftHeader title="Select Status" icon="check-box-icon" />
          </div>
          <div className="col-md-9">
            <div className="row">
              <div className="col-md-6">
                <FormControl>
                  <ReactSelect
                    name="status"
                    placeholder="Select status"
                    options={statusOptions}
                    value={state.status}
                    onChange={(value) => dispatch(setFormValue("status", value))}
                    isClearable
                    backspaceRemovesValue
                    styles={colorStyles}
                    rules="required"
                    messages={validationMessages.required}
                    testId="AccountingPeriod-Status-Select"
                    isDisabled={!isAdmin}
                  />
                </FormControl>
              </div>
            </div>
          </div>

          <button type="submit" style={{ display: "none" }} ref={submitButtonRef}>
            Save
          </button>
        </div>
      </Form>
      <div className="col-md-12">
        <div className="hr" />
      </div>

      <div className="col">
        <div className="buttons-at-end">
          <Button bordered small onClick={handleCancel} testId="AccountingPeriod-Cancel-Button">
            Cancel
          </Button>
          <Button
            small
            className="save-btn"
            loading={
              saveMutation.isLoading ||
              updateMutation.isLoading
            }
            onClick={handleSave}
            testId="AccountingPeriod-Submit-Button"
          >
            Save
          </Button>
        </div>
      </div>

      {
        state?.interimPeriods && state?.interimPeriods.length && (
          <InterimPeriodTable
            title="Interim Periods"
            data={getInterimPeriodTableData(state.interimPeriods)}
            columns={getInterimPeriodTableColumns()}
          />
        )
      }
    </BoxedContent>
  );
}
