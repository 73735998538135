import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Button } from "@hydra/atom/components";
import { useState } from "react";
import { isEmpty } from "lodash";
import { formatDate } from "@/utils/helpers";
import {
  StatusCircle,
  Status,
  CurrencyField
} from "@/components/common";
import { statusColorMap } from "@/utils/maps/statusColorMap";
import { handleDrawer } from "@/utils/modal/helpers";

function StatusCell({ value }) {
  return (
    <Status
      baseClassName="status-cell"
      status={value.value}
      bgColor={statusColorMap.get(value.value.toLowerCase())}
    >
      <StatusCircle color={statusColorMap.get(value.value.toLowerCase())} />
    </Status>
  );
}

StatusCell.propTypes = {
  value: PropTypes.object.isRequired,
};

function LinkCell({ value }) {
  return (
    <div className="link-cell">
      <Link
        to={`/work-order/details/${value?.value}`}
      >
        {value?.label}
      </Link>
    </div>
  );
}

LinkCell.propTypes = {
  value: PropTypes.object.isRequired,
};

function RateInputCell({ cell, handleValueChange, row }) {
  return (
    <div className="rate-input-cell">
      <CurrencyField
        key={`${row.original.index}-rate`}
        name={`${row.original.index}-rate`}
        value={cell.value}
        onChange={(value) => handleValueChange(row, value)}
      />
    </div>
  );
}
function ActionCell({ addMaterial, row }) {
  const [isClicked, setIsClicked] = useState(false);
  return (
    <div className="action-cell">
      {row.values.rate ? (
        <Button disabled={isClicked} key={row.original.key} onClick={() => { setIsClicked(true); addMaterial(row); }} small className="save-btn">
          Add
        </Button>
      ) : ""}
    </div>
  );
}

ActionCell.propTypes = {
  row: PropTypes.object.isRequired,
  addMaterial: PropTypes.func.isRequired
};

function CreatedAtDateCell({ value }) {
  return (
    <div className="date-cell">{formatDate(new Date(value))}</div>
  );
}

CreatedAtDateCell.propTypes = {
  value: PropTypes.string.isRequired,
};
function LookupCell({ value }) {
  if (!value) return <div />;

  return value?.value ? (
    <div className="link-cell">
      <Link
        to={handleDrawer(
          value.value,
          null,
          false,
          "dynamicObjectDrawers"
        )}
      >
        <TruncatedTextCell value={value.label} />
      </Link>
    </div>
  ) : (
    <TruncatedTextCell value={value.label} />
  );
}
LookupCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

LookupCell.defaultProps = {
  value: "",
};
export const getOverviewTableColumns = () => [
  {
    Header: "Title",
    accessor: "title",
  },
  {
    Header: "Quantity",
    accessor: "quantity",
  },
  {
    Header: "Unit",
    accessor: "unit",
  },
  {
    Header: "Rate",
    accessor: "rate",
    Cell: RateInputCell,
    collapse: true

  },
  {
    Header: "Amount",
    accessor: "amount",
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: ActionCell,
    collapse: true,
  },
];
function TruncatedTextCell({ value }) {
  return (
    <div className="truncated-text-cell">
      <p>{value}</p>
    </div>
  );
}
TruncatedTextCell.propTypes = {
  value: PropTypes.string.isRequired,
};

export const getOverviewTableData = (data) => data?.map((result) => {
  const { item } = result;

  const itemData = {
    key: result.id,
    title: "",
    quantity: result.quantity,
    rate: result?.rate || "",
    amount: result?.amount || "",
    unit: item?.uom,
    action: "",
  };

  if (item && !isEmpty(item)) {
    const { id, itemName, uom } = item;
    itemData.title = itemName;
    itemData.uom = uom;
    itemData.item = {
      label: itemName,
      value: id
    };
  } else {
    itemData.title = result.label;
  }

  return itemData;
});
