import { useMemo, useState, useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import {
  Tab, ReactSelect, Pagination,
  Input
} from "@hydra/atom/components";
import { DynamicObjectSelectField } from "@/components/dynamic/fields";
import {
  BoxedContent, Header, NoDataFound, Accordion,
  CustomizedDatePicker,
  Select,
  HeaderLeftContent
} from "@/components/common";
import { CardLayout, CardAnalytics } from "@/components/dashboard";
import { CheckboxFinanceMasterTable } from "@/components/finance";
import {
  getTaskTableColumns,
  getTaskTableData,
} from "@/components/workflow/taskTableData";
import { getTasks, getTasksCount, getAllWorkflowList } from "@/api/workflow/workflowApi";
import { skeletonLoadingRows, formatApiPayloadDate } from "@/utils/helpers";
import { TASK_STATUSES } from "@/components/workflow/constants";
import { getToken } from "@/api/user/authApi";
import { selectActiveApp } from "@/store/appSlice";
import { selectIsLoggedIn } from "@/store/userSlice";
import { useIsMobile, useDebounce } from "@/hooks";

const perPageOptions = [
  { label: "12", value: 12 },
  { label: "24", value: 24 },
  { label: "36", value: 36 },
];

function TasksDashboard() {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const [statusTab, setStatusTab] = useState([TASK_STATUSES.INPROGRESS]);
  const activeApp = useSelector(selectActiveApp);
  const isMobileView = useIsMobile();
  const [perPage, setPerPage] = useState(perPageOptions[0]);
  const [currentPage, setCurrentPage] = useState(0);
  const [filters, setFilters] = useState({
    workflowSchema: "",
    createdAt: null,
    recordNumber: "",
    unit: "",
    building: "",
    taskName: ""
  });
  const debouncedFilter = useDebounce(filters, 800);
  const handlePagination = (pageNumber, pageSize) => {
    setCurrentPage(pageNumber === 0 ? pageNumber : pageNumber - 1);
    setPerPage(pageSize);
  };

  const [currentTabValue, setCurrentTabValue] = useState(
    {
      label: "In Progress",
      value: "InProgress"
    },
  );
  const moduleName = useMemo(() => {
    switch (activeApp?.value) {
      case "Admin":
        return undefined;

      default:
        return activeApp?.label;
    }
  }, [activeApp]);

  const { data: countData } = useQuery(["task", getToken()], () => getTasksCount(), {
    enabled: Boolean(isLoggedIn)
  });

  const getTaskFilters = useCallback(() => {
    if (isMobileView) {
      if (!moduleName && !currentTabValue.value) {
        return {};
      }

      return {
        model: {
          status: statusTab,
        }
      };
    }

    if (!moduleName && !statusTab) {
      return {};
    }

    return {
      model: {
        status: statusTab,
        recordNumber: debouncedFilter?.recordNumber,
        unitNumber: debouncedFilter?.unit?.number || debouncedFilter?.unit?.value,
        buildingNumber: debouncedFilter?.building?.value,
        createdAt: debouncedFilter?.createdAt ?
          formatApiPayloadDate(new Date(debouncedFilter?.createdAt)) : null,
        workflowSchemaId: debouncedFilter?.workflowSchema?.value
      }
    };
  }, [moduleName, statusTab, currentTabValue, debouncedFilter]);

  const { data, isInitialLoading: isLoadingTasks } = useQuery(["task", currentPage,
    perPage.value, getToken(), {
      Take: currentPage,
      Limit: perPage.value,
      ...getTaskFilters()
    }],
  () => getTasks({
    Take: currentPage,
    Limit: perPage.value,
    ...getTaskFilters(),
  }),
  {
    enabled: Boolean(isLoggedIn)
  }
  );

  const totalCount = useMemo(() => data?.totalCount || 0, [data?.data]);

  const renderTable = () => (
    <div className="row my-3">
      <div className="col-md-3">
        <Select
          name="workflow"
          queryKey="workflow"
          placeholder="Select Workflow"
          value={filters?.workflowSchema}
          onChange={(e) => setFilters({ ...filters, workflowSchema: e })}
          optionsLoader={() => getAllWorkflowList()}
          dataAccessKey="data"
          optionLabelKey="workFlowName"
          optionValueKey="id"
          isClearable
        />
      </div>
      <div className="col-md-2">
        <Input
          className="input-height-fix"
          value={filters?.recordNumber}
          onChange={(e) => { setFilters({ ...filters, recordNumber: e }); }}
          placeholder="Task Number"
        />
      </div>
      <div className="col-md-2">
        <CustomizedDatePicker
          value={filters?.createdAt}
          onChange={(e) => { setFilters({ ...filters, createdAt: e ? new Date(e) : null }); }}
          placeholder="Created At"
          maxDate={new Date()}
        />
      </div>
      <div className="col-md-2">
        <DynamicObjectSelectField
          objectName="Unit"
          name="Unit"
          label={null}
          showLabel={false}
          value={filters.unit}
          onChange={(e) => { setFilters({ ...filters, unit: e || null }); }}
          optionLabelKey="name"
          optionValueKey="number"
          isDynamicObject
          placeholder="Unit"
          fieldFilters={
            {
              CurrentStatus: "Active"
            }
          }
        />
      </div>
      <div className="col-md-3">
        <DynamicObjectSelectField
          objectName="Building"
          name="Building"
          showLabel={false}
          label={null}
          value={filters.building}
          onChange={(e) => { setFilters({ ...filters, building: e || null }); }}
          optionLabelKey="name"
          optionValueKey="number"
          isDynamicObject
          placeholder="Building"
          fieldFilters={
            {
              CurrentStatus: "Active"
            }
          }
        />
      </div>
      {(!isLoadingTasks && data && !data?.data?.length) ?
        <NoDataFound title="No tasks found" /> : (
          <div className="col-12">
            <CheckboxFinanceMasterTable
              isLoading={isLoadingTasks}
              tableColumns={getTaskTableColumns(data?.data)}
              data={getTaskTableData(data?.data || skeletonLoadingRows(5))}
              showCheckbox={false}
            />
            <Pagination
              className="dashboard-pagination"
              options={perPageOptions}
              perPage={perPage}
              onSelectChange={(val) => handlePagination(currentPage, val)}
              pageRangeDisplayed={3}
              pageNo={currentPage}
              handlePageClick={(pageNo) => handlePagination(pageNo.selected + 1, perPage)}
              showResults
              offset={0}
              totalItems={totalCount}
              pageCount={Math.ceil(totalCount / perPage.value)}
              reactPaginateProps={{
                previousLabel: <span className="material-icons">&#xe5cb;</span>,
                nextLabel: <span className="material-icons">&#xe5cc;</span>,
                forcePage: currentPage - 1,
              }}
            />
          </div>
        )}
    </div>
  );

  const handleStatusTabChange = (index) => {
    const status = {
      0: [TASK_STATUSES.INPROGRESS],
      1: [TASK_STATUSES.COMPLETED, TASK_STATUSES.APPROVED, TASK_STATUSES.UPDATED, TASK_STATUSES.VALID, TASK_STATUSES.CREATED, TASK_STATUSES.SUBMITTED],
      2: [TASK_STATUSES.REJECTED, TASK_STATUSES.INVALID]
    };
    setStatusTab(status[index]);
  };

  const tabs = ["Pending Tasks", "Completed Tasks", "Declined Tasks"];
  const tabsOption = [
    {
      label: "Pending Tasks",
      value: "InProgress"
    },
    {
      label: "Completed Tasks",
      value: "Completed"
    },
    {
      label: "Declined",
      value: "Rejected"
    }
  ];

  return (
    <BoxedContent>
      {!isMobileView ? (
        <>
          <Header
            showBreadcrumb
            leftContent={<HeaderLeftContent title="Tasks" icon="list-2-stroke-icon" />}
          />
          <div className="row">
            <div className="col-md-7">
              <Tab className="table-tabs-container" activeTabIndex={tabsOption.findIndex((option) => option.value === "InProgress") || 0} tabs={tabs} onClick={handleStatusTabChange} />
            </div>
          </div>
          {renderTable()}
        </>
      ) : (
        <>
          <div className="row cards-row tasks-row">
            <div className="col-md-4">
              <CardLayout
                className="analytics"
                title="Total Tasks"
              >
                <CardAnalytics title={countData?.total || 0} />
              </CardLayout>
            </div>
            <div className="col-md-4">
              <CardLayout
                className="analytics"
                title="Completed"
              >
                <CardAnalytics title={countData?.completed || 0} />
              </CardLayout>
            </div>
            <div className="col-md-4">
              <CardLayout className="analytics" title="Pending">
                <CardAnalytics title={countData?.pending || 0} />
              </CardLayout>
            </div>
          </div>
          <hr className="full-hr" />
          <div className="plan-board-accordion-blue">
            <Accordion
              title="Work Orders"
            >
              <div className="col-md-4 tasks-dropdown">
                <ReactSelect
                  name="currency"
                  placeholder="Select Status"
                  options={tabsOption}
                  value={currentTabValue}
                  onChange={(value) =>
                    setCurrentTabValue(value)}
                />
              </div>
              {renderTable()}
            </Accordion>
          </div>
        </>
      )}

    </BoxedContent>
  );
}

export default TasksDashboard;
