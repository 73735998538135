const staticObjectMap = new Map([
  // Finance
  ["TaxRuleObjectName", "TaxRule"],
  ["BudgetCategoryObjectName", "BudgetCategory"],
  ["BudgetObjectName", "Budget"],
  ["AccountingPeriodObjectName", "AccountingPeriod"],
  ["LedgerAccountObjectName", "LedgerAccount"],
  ["JournalEntryObjectName", "JournalEntry"],
]);

export default staticObjectMap;
