import {
  useReducer,
  useEffect,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
  useCallback,
  useMemo,
} from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useMutation, useQueries, useQuery } from "@tanstack/react-query";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { camelize, pascalize } from "humps";
import { Loader } from "@hydra/atom/components";
import { isEmpty, kebabCase, startCase } from "lodash";
import { selectActiveCompany, selectCompanies } from "@/store/appSlice";
import showToast from "@/utils/toast/helpers";
import { Header, NoDataFound, HeaderLeftContent } from "@/components/common";
import ActionDropdown from "./ActionDropdown";
import { DynamicForm, DynamicLinkedViews } from "@/components/dynamic";
import { getDynamicObjectByNameWithCamelizedFieldNames } from "@/api/dynamic/dynamicObjectSchemaApi";
import {
  createDynamicObjectRecord,
  updateDynamicObjectRecord,
  bulkCreateDynamicObjectRecord,
  uploadAttachmentWithDynamicObjectRecord,
  getDynamicObjectRecordById,
  getDynamicObjectRecords,
  getDynamicAutoNumber,
  bulkCreateInsertDynamicObjectRecord,
} from "@/api/dynamic/dynamicObjectNameApi";
import { bulkCreateCompanyAccounts } from "@/api/admin/companyAccountApi";
import {
  dynamicFormReducer,
  initialState,
  resetFormState,
  setInitialState,
  setFormValue,
  setState,
} from "@/reducers/dynamic/dynamicFormReducer";
import {
  prepareFieldValue,
  prepareDynamicObjectState,
  prepareDynamicObjectData,
  prepareDynamicObjectDefaultValues,
  checkDynamicObjectPermission,
  validateFields,
} from "@/utils/dynamic/helpers";
import intermediateObjectFieldNamesMap from "@/utils/maps/intermediateObjectFieldNamesMap";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import { UnitAmenityForm } from "@/components/leasing";
import { selectPermissions } from "@/store/userSlice";
import { useScrollToTop, useCompanyAccount } from "@/hooks";
import { selectAutoNumber } from "@/store/autoNumberSlice";
import { checkDateInAccountingPeriod } from "@/api/finance/accountingPeriodApi";
import { getCompany } from "@/api/user/authApi";

// TODO: extract actions into a separate file or write a trigger
const updateAmenities = async (unitId, amenities) => {
  const selectedAmenities = amenities.map((amenity) => {
    if (amenity.selected) {
      return {
        id: amenity.id,
        unit: amenity.unit.includes(unitId) ?
          amenity.unit :
          [unitId, ...amenity.unit],
      };
    }

    return amenity;
  });

  Promise.all(
    selectedAmenities.map(({ id, unit }) =>
      updateDynamicObjectRecord(dynamicObjectMap.get("AmenityObjectName"), id, {
        unit,
      })
    )
  );
};

// TODO: extract actions into a separate file or write a trigger
const updateFacilities = async (unitId, facilities) => {
  const selectedFacilities = facilities.map((facility) => {
    if (facility.selected) {
      return {
        id: facility.id,
        unit: facility.unit.includes(unitId) ?
          facility.unit :
          [unitId, ...facility.unit],
      };
    }

    return facility;
  });

  Promise.all(
    selectedFacilities.map(({ id, unit }) =>
      updateDynamicObjectRecord(
        dynamicObjectMap.get("FacilityObjectName"),
        id,
        {
          unit,
        }
      )
    )
  );
};

function HeaderRightContent({
  objectName,
  isEditing,
  selectedId,
  recordValues,
  data,
  children,
}) {
  return (
    <div className="buttons-at-end">
      {children}
      {isEditing ? (
        <ActionDropdown
          objectName={pascalize(objectName)}
          id={selectedId}
          data={recordValues}
          actions={data?.actions}
          removeActions={["Edit"]}
          showTableActions
          testId="Action-Dropdown"
          trigger={(
            <div className="action-dropdown-trigger">
              <span className="text">Actions</span>
              <span className="material-icons-outlined">expand_more</span>
            </div>
          )}
        />
      ) : null}
    </div>
  );
}

HeaderRightContent.propTypes = {
  objectName: PropTypes.string,
  isEditing: PropTypes.bool,
  selectedId: PropTypes.string,
  recordValues: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.oneOf([null]),
  ]),
  data: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([null])]),
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.oneOf([null]),
  ]),
};

HeaderRightContent.defaultProps = {
  objectName: "",
  isEditing: false,
  selectedId: "",
  recordValues: null,
  data: null,
  children: null,
};

export const defaultComponents = {
  HeaderRightContent,
};

const DynamicFormContainer = forwardRef(
  (
    {
      objectName: objectNameFromProps,
      selectedId: idFromProps,
      onSuccess,
      onError,
      isReadOnly,
      initialData,
      setIsLoading,
      showHeader,
      showLinkedViews,
      navigate: shouldNavigateBack,
      title,
      components,
      bulkApi = null,
      ...rest
    },
    ref
  ) => {
    const companies = useSelector(selectCompanies);
    const activeCompany = useSelector(selectActiveCompany);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { objectName: objectNameFromParams, id: idFromParams } = useParams();
    const isRecordValueLoaded = useRef(false);
    const [isBulkLoading, setIsBulkLoading] = useState(false);
    const areRelationalFieldValuesLoaded = useRef(false);
    const [state, dispatch] = useReducer(dynamicFormReducer, initialState);
    const { scrollToTopRef } = useScrollToTop(state?.activeStepIndex || 0);
    const [searchParameters] = useSearchParams();
    const [recordKey, setRecordKey] = useState("");
    const objectName = objectNameFromProps ?? objectNameFromParams;
    const selectedId = idFromProps ?? idFromParams;
    const isEditing = selectedId ?
      selectedId.startsWith(kebabCase(objectName)) :
      false;
    const permissions = useSelector(selectPermissions);
    const workflowId = searchParameters.get("workflowId");
    const autoNumber = useSelector(selectAutoNumber(objectName));
    const companyAccounts = useCompanyAccount({
      params: {
        isLinkedWithRecord: true,
        recordId: isEditing ? selectedId : "",
        includeCompanyId: false,
      },
      isEnabled: isEditing,
      format: "RecordState",
    });

    const hasEditPermission = checkDynamicObjectPermission({
      permissions,
      scope: pascalize(objectName ?? ""),
      action: "Update",
    });

    const hasInsertPermission = checkDynamicObjectPermission({
      permissions,
      scope: pascalize(objectName ?? ""),
      action: "Insert",
    });

    useImperativeHandle(
      ref,
      () => ({
        setFormState(payload) {
          dispatch(setState(payload));
        },
        setFormValue(key, value) {
          dispatch(setFormValue(key, value));
        },
        getState() {
          return state;
        },
      }),
      [state]
    );

    const readOnlyParam = searchParameters.get("readOnly") === "true";

    const isFormReadOnly = isReadOnly || readOnlyParam;

    const { data, isInitialLoading: isLoadingSchema } = useQuery(
      ["dynamic-object-camelized-schema", objectName, rest.layout],
      () => getDynamicObjectByNameWithCamelizedFieldNames(objectName),
      {
        enabled: objectName !== undefined,
      }
    );

    const fields = data?.document?.filter((field) => !field.hidden) ?? [];

    const hasAutoNumberField = useMemo(
      () => data?.document?.some((f) => f.objectFieldType === "AutoNumber"),
      [data]
    );

    const companyAccountFields =
      data?.document?.filter((field) => field.isCompanyAccount) ?? [];

    const { data: recordValues, isInitialLoading: isLoadingRecord } = useQuery(
      [kebabCase(objectName), selectedId],
      () => getDynamicObjectRecordById(objectName, selectedId),
      {
        enabled: isEditing,
      }
    );
    const generateNumberMutation = useMutation(
      () =>
        getDynamicAutoNumber({
          objectName: pascalize(camelize(objectName)),
          fieldName: "Number",
        }),
      {
        onError: () => {
          showToast("Could not generate auto number. Please refresh!", "error");
        },
        onSuccess: (response) => {
          if (response.status === 200) {
            dispatch(setFormValue("number", response.number));
          }
        },
      }
    );

    useEffect(() => {
      if (!isEditing && data && activeCompany && companies.length) {
        const defaultValues = prepareDynamicObjectDefaultValues(data?.document);
        const { defaultCurrency } = activeCompany;

        defaultValues.currency = defaultCurrency;

        if (data?.multiCompany) {
          defaultValues.company = companies;
        } else {
          defaultValues.company = {
            label: activeCompany.name,
            value: activeCompany.id,
          };
        }

        let initialStateValue = {
          ...defaultValues,
        };

        if (initialData && !isEmpty(initialData)) {
          initialStateValue = {
            ...initialStateValue,
            ...initialData,
          };
          if (autoNumber) {
            initialStateValue.number = autoNumber;
          }
        }

        dispatch(setInitialState(initialStateValue));
      }
    }, [data, initialData, activeCompany]);

    useEffect(() => {
      if (hasAutoNumberField && !isEditing) {
        generateNumberMutation.mutate();
      }
    }, [hasAutoNumberField]);

    useEffect(() => {
      if (data && recordValues && isEditing && !isRecordValueLoaded.current) {
        const recordState = prepareDynamicObjectState(
          recordValues,
          data?.document
        );
        let initialStateValue = {
          ...recordState,
        };

        if (initialData && !isEmpty(initialData)) {
          initialStateValue = {
            ...initialStateValue,
            ...initialData,
          };
        }

        dispatch(setInitialState(initialStateValue));
        isRecordValueLoaded.current = true;
      }
    }, [data, recordValues]);

    useEffect(() => {
      if (companyAccounts) {
        dispatch(setInitialState(companyAccounts));
      }
    }, [companyAccounts]);

    const relationalFields =
      fields.filter((f) =>
        ["table"].includes(f.objectFieldType.toLowerCase())
      ) || [];

    const queries = relationalFields.map((relationalField, index) => ({
      queryKey: [
        "getDynamicObjectRecords",
        relationalField.intermediateObjectName,
        1,
        100,
        "RelationalRecord",
        selectedId,
        `relational-field-${index}`,
      ],
      queryFn: () =>
        getDynamicObjectRecords(relationalField.intermediateObjectName, {
          takePage: 1,
          limitPage: undefined,
          sortBy:
            pascalize(relationalField.intermediateObjectName) ===
            dynamicObjectMap.get("BlanketAgreementPaymentDetailObjectName") ?
              "PaymentDate" :
              "CreatedAt",
          sortType: "DESC",
          [pascalize(intermediateObjectFieldNamesMap.get("DetailIdFieldName"))]:
            selectedId,
        }),
      enabled: isEditing,
    }));

    const relationalFieldValues = useQueries({
      queries,
    });

    useEffect(() => {
      const areAllQueriesLoaded = relationalFieldValues.every(
        (r) => !r.isLoading
      );

      if (
        areAllQueriesLoaded &&
        relationalFieldValues &&
        !areRelationalFieldValuesLoaded.current &&
        recordValues
      ) {
        relationalFields.forEach((relationalField, index) => {
          const relationalFieldData = relationalFieldValues[index]?.data?.data;

          if (relationalFieldData) {
            const relationalFieldValue = [];
            const additionalFieldsKey = "fields";

            relationalFieldData.forEach((f) => {
              const value = prepareDynamicObjectState(
                f,
                relationalField[additionalFieldsKey]
              );

              value.id = f.id;

              value[intermediateObjectFieldNamesMap.get("DetailIdFieldName")] =
                f[intermediateObjectFieldNamesMap.get("DetailIdFieldName")];

              relationalFieldValue.push(value);
            });

            dispatch(setFormValue(relationalField.name, relationalFieldValue));

            if (index === relationalFieldValues.length - 1) {
              areRelationalFieldValuesLoaded.current = true;
            }
          }
        });
      }
    }, [relationalFieldValues, recordValues]);

    const handleLoading = (isLoading) => {
      setLoading(isLoading);
      setIsLoading(isLoading);
    };

    const handleCancel = () => {
      navigate(-1);
    };

    const handleSuccess = () => {
      handleLoading(false);
      if (shouldNavigateBack) {
        navigate(-1);
      }
    };

    const handleError = () => {
      handleLoading(false);
      onError();
    };

    const handleSubmittedActions = async (newRecordKey) => {
      let recordId = newRecordKey;

      if (isEditing) {
        recordId = selectedId;
      }

      switch (kebabCase(objectName)) {
        case kebabCase(dynamicObjectMap.get("UnitObjectName")):
          updateAmenities(recordId, state.unitAmenities ?? []);
          updateFacilities(recordId, state.unitFacilities ?? []);
          break;

        default:
          break;
      }
    };

    const onSubmitted = (newRecordKey = "") => {
      if (!bulkApi) {
        handleSubmittedActions(newRecordKey);
        let toastMessage = "";

        if (!isEditing && newRecordKey) {
          toastMessage = `${startCase(objectName)} created successfully`;
        } else {
          toastMessage = `${startCase(objectName)} updated successfully`;
        }

        showToast(toastMessage, "success");
        dispatch(resetFormState());
        handleSuccess();
        onSuccess(newRecordKey);
      }
    };

    const onSubmittedChild = (newRecordKey = "") => {
      handleSubmittedActions(newRecordKey);
      let toastMessage = "";

      if (!isEditing && newRecordKey) {
        toastMessage = `${startCase(objectName)} created successfully`;
      } else {
        toastMessage = `${startCase(objectName)} updated successfully`;
      }

      showToast(toastMessage, "success");
      dispatch(resetFormState());
      handleSuccess();
      onSuccess(newRecordKey);
    };

    const saveMutation = useMutation(({ dynamicObjectName, dataObject }) =>
      createDynamicObjectRecord(dynamicObjectName, dataObject)
    );

    const saveBulkMutation = useMutation(
      ({ dynamicObjectName, dataObjects }) =>
        bulkCreateDynamicObjectRecord(dynamicObjectName, dataObjects),
      {
        onMutate: () => {
          setIsBulkLoading(true);
        },
        onSuccess: (response, variables) => {
          const {
            tableField,
            additionalFieldsKey,
            dynamicObjectName,
            stateValue,
          } = variables;

          const childAttachmentFields = tableField[additionalFieldsKey].filter(
            (cf) => cf.objectFieldType.toLowerCase() === "attachment"
          );

          if (childAttachmentFields.length) {
            childAttachmentFields.forEach((childAttachmentField) => {
              response?.data?.forEach(({ id }, index) => {
                if (stateValue[index][childAttachmentField.name]) {
                  uploadAttachmentWithDynamicObjectRecord({
                    recordId: id,
                    attachmentFieldName: childAttachmentField.name,
                    files: stateValue[index][childAttachmentField.name],
                    objectName: dynamicObjectName,
                  });
                }
              });
            });
          }
          setIsBulkLoading(false);
        },
        onError: (error, variables, context) => {
          console.error("Error occurred during bulk save:", error);
          showToast(
            `Could not save ${variables.dynamicObjectName}. Edit the record and try again!`,
            "error"
          );
          if (context?.rollback) {
            context.rollback();
          }
          setIsBulkLoading(false);
        },
      }
    );

    const saveBulkInsertMutation = useMutation(
      ({ dynamicObjectName, dataObjects }) =>
        bulkCreateInsertDynamicObjectRecord({
          objectName: dynamicObjectName,
          useImport: true,
          SkipBuildingObjects: true,
          dataObjects,
        }),
      {
        onMutate: () => {
          setIsBulkLoading(true);
        },
        onSuccess: (response, variables) => {
          const {
            tableField,
            additionalFieldsKey,
            dynamicObjectName,
            stateValue,
          } = variables;

          const childAttachmentFields = tableField[additionalFieldsKey].filter(
            (cf) => cf.objectFieldType.toLowerCase() === "attachment"
          );

          if (childAttachmentFields.length) {
            childAttachmentFields.forEach((childAttachmentField) => {
              response?.data?.forEach(({ id }, index) => {
                if (stateValue[index][childAttachmentField.name]) {
                  uploadAttachmentWithDynamicObjectRecord({
                    recordId: id,
                    attachmentFieldName: childAttachmentField.name,
                    files: stateValue[index][childAttachmentField.name],
                    objectName: dynamicObjectName,
                  });
                }
              });
            });
          }

          if (bulkApi && recordKey) {
            onSubmittedChild(recordKey);
          }

          setIsBulkLoading(false);
        },
        onError: (error) => {
          console.error("Error occurred during bulk insert:", error);
          showToast("Could not insert records. Please try again!", "error");
          setIsBulkLoading(false);
        },
      }
    );

    const updateMutation = useMutation(
      ({ recordId, dataObject, dynamicObjectName }) =>
        updateDynamicObjectRecord(dynamicObjectName, recordId, dataObject)
    );

    const uploadAttachments = (
      attachmentFields,
      newRecordKey,
      parentObjectName
    ) => {
      attachmentFields.forEach((attachmentField) => {
        if (state[attachmentField.name]?.length) {
          uploadAttachmentWithDynamicObjectRecord({
            recordId: newRecordKey,
            attachmentFieldName: attachmentField.name,
            files: state[attachmentField.name],
            objectName: parentObjectName,
          });
        }
      });
    };

    const uploadCompanyAccounts = async (newRecordKey) => {
      const companyAccountData = [];

      state.company.forEach((company) => {
        companyAccountFields.forEach((companyAccountField) => {
          const { name } = companyAccountField;
          const { label: companyName, value: companyId } = company;
          const camelCaseCompanyName = camelize(companyName);
          const pascalCaseFieldName = pascalize(name);
          const stateKey = `${camelCaseCompanyName}${pascalCaseFieldName}`;
          if (state[stateKey]) {
            const { value } = state[stateKey];

            companyAccountData.push({
              accountType: pascalCaseFieldName,
              accountId: value,
              companyId,
              isLinkedWithRecord: true,
              additionalProperties: {
                recordId: newRecordKey,
              },
            });
          }
        });
      });

      try {
        await bulkCreateCompanyAccounts(companyAccountData);
      } catch (error) {
        showToast(
          "Could not save company accounts. Edit the record and try again!",
          "error"
        );
      }
    };

    const saveData = async (recordData) => {
      try {
        const saveMutationData = await saveMutation.mutateAsync({
          dynamicObjectName: objectName,
          dataObject: recordData,
        });

        const attachmentFields = fields.filter(
          (f) => f.objectFieldType.toLowerCase() === "attachment"
        );

        const newRecordKey = saveMutationData.id;

        if (companyAccountFields.length) {
          await uploadCompanyAccounts(newRecordKey);

          if (!relationalFields.length && !attachmentFields.length) {
            onSubmitted(newRecordKey);
            return;
          }
        }

        if (attachmentFields.length) {
          await uploadAttachments(attachmentFields, newRecordKey, objectName);

          if (!relationalFields.length) {
            onSubmitted(newRecordKey);
            return;
          }
        }

        if (relationalFields.length) {
          const relationalPromises = relationalFields.map(async (f) => {
            const dataObjects = [];
            const additionalFieldsKey = "fields";

            if (state[f.name] && state[f.name].length) {
              state[f.name].forEach((value) => {
                const dataObject = {
                  [intermediateObjectFieldNamesMap.get("DetailIdFieldName")]:
                    newRecordKey,
                };

                f[additionalFieldsKey].forEach((field) => {
                  if (field.name === "company") {
                    dataObject[field.name] = getCompany();
                  } else if (
                    field.name === "currency" &&
                    !dataObject[field.name]
                  ) {
                    dataObject[field.name] =
                      activeCompany.defaultCurrency.value;
                  } else {
                    dataObject[field.name] = prepareFieldValue(field, value);
                  }
                });

                dataObjects.push(dataObject);
              });
            }

            try {
              if (bulkApi) {
                setRecordKey(newRecordKey);
                await saveBulkInsertMutation.mutateAsync({
                  tableField: f,
                  stateValue: state[f.name],
                  additionalFieldsKey,
                  dynamicObjectName: f.intermediateObjectName,
                  dataObjects,
                });
              } else {
                await saveBulkMutation.mutateAsync({
                  tableField: f,
                  stateValue: state[f.name],
                  additionalFieldsKey,
                  dynamicObjectName: f.intermediateObjectName,
                  dataObjects,
                });
              }
            } catch (error) {
              console.error("Bulk mutation failed:", error);
            }
          });

          await Promise.all(relationalPromises);
        }

        onSubmitted(newRecordKey);
      } catch (error) {
        console.error("Save failed:", error);
        showToast(
          `Could not save ${startCase(objectName)}. Try again!`,
          "error"
        );
        handleError();
      }
    };

    const updateData = (recordData) => {
      updateMutation.mutate(
        {
          dynamicObjectName: objectName,
          recordId: selectedId,
          dataObject: recordData,
        },
        {
          onError: () => {
            showToast(
              `Could not update ${startCase(objectName)}. Try again!`,
              "error"
            );
            onError();
          },
          onSuccess: () => {
            onSubmitted(selectedId);
          },
        }
      );
    };

    const updateTableDataMutation = useMutation(
      ({ recordId, dataObject, objectName: tableObjectName }) =>
        updateDynamicObjectRecord(tableObjectName, recordId, dataObject)
    );

    const checkPermissionForTableDataUpdate = () => {
      if (objectName === dynamicObjectMap.get("ContractObjectName")) {
        return true;
      }
      return false;
    };

    const handleSubmit = async () => {
      handleLoading(true);
      if (rest.onBeforeSave) {
        const result = await rest.onBeforeSave();
        if (!result) {
          handleLoading(false);
          return;
        }
      }

      if (data?.multiCompany) {
        if (!state?.company?.length) {
          handleLoading(false);
          showToast(
            "Company is required. Please select at least one company.",
            "error"
          );
          return;
        }
      }

      let objectFields = data?.document;

      const tableFields = objectFields.filter(
        (f) => f.objectFieldType === "Table"
      );

      const tableFieldErrors = validateFields(tableFields, state);

      // Validate table fields
      if (tableFieldErrors.length) {
        handleLoading(false);
        showToast(tableFieldErrors[0], "error");
        return;
      }

      let fieldError = false;

      // Validate child table fields
      for (let i = 0; i < tableFields.length; i += 1) {
        const tableField = tableFields[i];
        const childFields = tableField.fields;
        const tableFieldValue = state[tableField.name];
        let hasErrors = false;
        if (tableFieldValue && Array.isArray(tableFieldValue)) {
          tableFieldValue.forEach((row) => {
            if (!hasErrors) {
              const errors = validateFields(childFields, row, tableField.label);

              if (errors.length) {
                showToast(errors[0], "error");
                hasErrors = true;
              }
            }
          });
        }
        if (hasErrors) {
          fieldError = true;
          break;
        }
      }

      if (fieldError) {
        handleLoading(false);
        return;
      }

      // TODO: refactor when activity log is functional
      if (
        data?.objectName === dynamicObjectMap.get("ServiceRequestObjectName")
      ) {
        objectFields = objectFields.filter(
          (field) => !["notes", "noteStatus"].includes(field.name)
        );
      }

      const recordData = prepareDynamicObjectData(
        objectFields,
        state,
        isEditing
      );

      if (workflowId) {
        recordData.workflowId = workflowId;
      }

      if (!isEditing) {
        if (typeof recordData?.transactionDate === "string") {
          const response = await checkDateInAccountingPeriod(
            recordData.transactionDate
          );

          if (response.status !== "Open") {
            showToast(
              "Transaction date is outside of the current open accounting period",
              "error"
            );
            handleLoading(false);
            return;
          }
        }
        saveData(recordData);
        return;
      }

      if (checkPermissionForTableDataUpdate) {
        const tableDataObject = state.updatedTableData || {};

        Object.keys(tableDataObject).forEach((key) => {
          const tableData = tableDataObject[key];

          if (Array.isArray(tableData)) {
            tableData.forEach((row) => {
              const { recordId, objectName: tableObjectName, dataObject } = row;

              updateTableDataMutation.mutate({
                recordId,
                objectName: tableObjectName,
                dataObject,
              });
            });
          }
        });
      }

      updateData(recordData);
    };

    const getPageTitle = () => {
      if (title) return title;

      return selectedId ?
        `Edit ${startCase(objectName)}` :
        `New ${startCase(objectName)}`;
    };

    const renderAdditionalFields = () => {
      switch (kebabCase(objectName)) {
        case kebabCase(dynamicObjectMap.get("UnitObjectName")):
          return (
            <>
              <UnitAmenityForm
                objectName={dynamicObjectMap.get("AmenityObjectName")}
                id={selectedId}
                building={state.building}
                onChange={(value) =>
                  dispatch(setFormValue("unitAmenities", value))}
              />
              <UnitAmenityForm
                objectName={dynamicObjectMap.get("FacilityObjectName")}
                id={selectedId}
                building={state.building}
                onChange={(value) =>
                  dispatch(setFormValue("unitFacilities", value))}
              />
            </>
          );

        default:
          return null;
      }
    };

    const getUnauthorizedAccessTitle = useCallback(
      () =>
        `You do not have access to ${isEditing ? "edit" : "create"} ${startCase(
          data?.label
        )}`,
      [isEditing, hasEditPermission, hasInsertPermission, data]
    );

    if (isLoadingSchema || isLoadingRecord) return <Loader />;

    if (
      ((isEditing && !hasEditPermission) ||
        (!isEditing && !hasInsertPermission)) &&
      permissions
    ) {
      return (
        <div ref={scrollToTopRef}>
          {showHeader && (
            <Header
              showBreadcrumb
              leftContent={<HeaderLeftContent title={getPageTitle()} />}
            />
          )}

          <NoDataFound
            title={getUnauthorizedAccessTitle()}
            buttonText="Go Back"
            onClick={() => navigate(-1)}
          />
        </div>
      );
    }

    return (
      <div ref={scrollToTopRef}>
        {showHeader && (
          <Header
            showBreadcrumb
            leftContent={<HeaderLeftContent title={getPageTitle()} />}
            rightContent={(
              <components.HeaderRightContent
                objectName={objectName}
                isEditing={isEditing}
                selectedId={selectedId}
                recordValues={recordValues}
                data={data}
              />
            )}
          />
        )}
        <DynamicForm
          objectName={objectName}
          selectedId={selectedId}
          isEditing={isEditing}
          state={state}
          isBulkLoading={isBulkLoading}
          dispatch={dispatch}
          handleSubmit={handleSubmit}
          objectData={data}
          disabled={isFormReadOnly}
          handleCancel={handleCancel}
          isLoading={loading}
          renderAdditionalFields={renderAdditionalFields}
          updateTableDataOnSubmit={checkPermissionForTableDataUpdate()}
          {...rest}
        />
        {isEditing && showLinkedViews && data?.links.length > 0 && (
          <>
            <DynamicLinkedViews linkedObjects={data?.links} id={selectedId} />
            <hr className="full-hr" />
          </>
        )}
      </div>
    );
  }
);

DynamicFormContainer.propTypes = {
  objectName: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  selectedId: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([null])]),
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
  setIsLoading: PropTypes.func,
  initialData: PropTypes.oneOfType([PropTypes.object, PropTypes.oneOf([null])]),
  showHeader: PropTypes.bool,
  showLinkedViews: PropTypes.bool,
  navigate: PropTypes.bool,
  title: PropTypes.string,
  components: PropTypes.object,
};

DynamicFormContainer.defaultProps = {
  objectName: null,
  selectedId: null,
  onSuccess: () => {},
  onError: () => {},
  setIsLoading: () => {},
  initialData: null,
  showHeader: false,
  showLinkedViews: false,
  navigate: true,
  title: "",
  components: defaultComponents,
};

export default DynamicFormContainer;
