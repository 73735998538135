/* eslint-disable no-plusplus */
import { range } from "lodash";
import {
  emptyTextCell,
  nonEditable,
  textCell,
  bottomLine,
  showZero,
  noSideBorders,
  headerCell
} from "../cells";
import { HEADER_ROW_ID, ROW_HEIGHT } from "../constants";
import { formatCurrency } from "@/utils/helpers";

function years() {
  return new Array(4).fill(0);
}

function getHeaderRow(columns) {
  return {
    rowId: HEADER_ROW_ID,
    height: ROW_HEIGHT,
    cells: columns.map((column) => headerCell(column.label)),
  };
}

function getGroupRows(title, groups, columns, total) {
  if (groups?.length) {
    return [
      {
        rowId: `${title}Header`,
        height: ROW_HEIGHT,
        cells: [

          bottomLine(nonEditable(textCell({ text: title }), "font-bold light-green-bg ")),
          ...years().map(() => noSideBorders(bottomLine(nonEditable(emptyTextCell(), "light-green-bg")))
          ),
          bottomLine(nonEditable(emptyTextCell(), "light-green-bg")),
          bottomLine(nonEditable(emptyTextCell(), "light-green-bg"))
        ]
      },
      ...groups.map((item, index) => ({
        rowId: item.id,
        height: ROW_HEIGHT,
        cells: columns.map((column) => {
          const textCellArgs = {};
          if (column.formatter) {
            textCellArgs.text = column.formatter(item[column.columnId], index);
          } else {
            textCellArgs.text = item[column.columnId] ?? "";
          }
          return nonEditable(textCell(textCellArgs), column.className || "");
        }),
      })),
      {
        rowId: `${title}Footer`,
        height: ROW_HEIGHT,
        cells: [
          nonEditable(textCell({ text: "Group Total" }), "font-bold justify-content-start total-row"),
          nonEditable(showZero(textCell({ text: "" })), "total-row"),
          nonEditable(showZero(textCell({ text: "" })), "total-row"),
          nonEditable(showZero(textCell({ text: "" })), "total-row"),
          nonEditable(showZero(textCell({ text: total })), "total-row"),
          nonEditable(showZero(textCell({ text: "" })), "total-row"),
          nonEditable(showZero(textCell({ text: "" })), "total-row"),
        ]
      }
    ];
  }
  return [];
}

export function getTenantStatementRows({
  data,
  columns
}) {
  const total = formatCurrency(data.data.rent?.reduce((acc, curr) => acc + Number(curr?.total), 0));
  return [
    getHeaderRow(columns),
    ...getGroupRows("Rent", data.data.rent, columns, total),
  ];
}

function getRecieptGroupRows(groups, columns, totalBalance, totalCredit, totalDebit) {
  if (groups?.length) {
    return [
      ...groups.map((item, index) => ({
        rowId: item.id,
        height: ROW_HEIGHT,
        cells: columns.map((column) => {
          const textCellArgs = {};

          if (column.formatter) {
            textCellArgs.text = column.formatter(item[column.columnId], index);
          } else {
            textCellArgs.text = item[column.columnId] ?? "";
          }

          return nonEditable(textCell(textCellArgs), column.className || "");
        }),
      })),
      {
        rowId: "Total-Footer",
        height: ROW_HEIGHT,
        cells: [
          ...range(0, columns.length - 4).map(() => headerCell("", "total-row")),
          headerCell(
            "Total", "total-row"),
          headerCell(formatCurrency(totalDebit || 0), "total-row total-row-credit"),
          headerCell(formatCurrency(totalCredit || 0), "total-row total-row-credit"),
          headerCell(formatCurrency(totalBalance || 0), "total-row total-row-credit"),
        ],
      },
    ];
  }
  return [];
}

export function getReceiptRows({
  data,
  columns
}) {

  if (!data) {
    return [];
  }

  let totalBalance = 0;
  let totalCredit = 0;
  let totalDebit = 0;
  data.data?.forEach((item) => {
    totalBalance += item.runningBalance || 0;
    totalCredit += item.credit || 0;
    totalDebit += item.debit || 0;
  });

  return [getHeaderRow(columns),
    ...getRecieptGroupRows(data.data, columns, totalBalance, totalCredit, totalDebit)];
}
