import { getColumnWidth } from "../helpers";
import { formatCurrency, formatDate } from "@/utils/helpers";

export default function getBounceChequeColumns() {
  const windowWidth = window.innerWidth - 300;
  const totalColumns = 14;

  const colArr = [
    {
      label: "Bounce Cheque Number", value: true, columnId: "bounceChequeNumber", width: getColumnWidth(1 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Component Number", value: true, columnId: "componentNumber", width: getColumnWidth(2 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Component Name", value: true, columnId: "componentName", width: getColumnWidth(2 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Unit Number", value: true, columnId: "unitNumber", width: getColumnWidth(1 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Unit Mark", value: true, columnId: "unitMark", width: getColumnWidth(1 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Unit Type", value: true, columnId: "unitType", width: getColumnWidth(1 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Unit Status", value: true, columnId: "unitStatus", width: getColumnWidth(1 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Contract Number", value: true, columnId: "contractNumber", width: getColumnWidth(2 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Customer Number", value: true, columnId: "customerNumber", width: getColumnWidth(1 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Customer Name", value: true, columnId: "customerName", width: getColumnWidth(1 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Tenant Type", value: true, columnId: "tenantType", width: getColumnWidth(1 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Payment Receipt Date", value: true, columnId: "paymentReceiptDate", width: getColumnWidth(1 / totalColumns, windowWidth), resizable: true, formatter: (value) => (value ? String(value ? formatDate(new Date(value)) : "") : "")
    },
    {
      label: "Payment Receipt Number", value: true, columnId: "paymentReceiptNumber", width: getColumnWidth(1 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "System Cheque Ref", value: true, columnId: "systemChequeRef", width: getColumnWidth(1 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Cheque No", value: true, columnId: "chequeNo", width: getColumnWidth(1 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Due Date", value: true, columnId: "dueDate", width: getColumnWidth(1 / totalColumns, windowWidth), resizable: true, formatter: (value) => (value ? String(value ? formatDate(new Date(value)) : "") : "")
    },
    {
      label: "Amount", value: true, columnId: "amount", width: getColumnWidth(1 / totalColumns, windowWidth), resizable: true, formatter: (value) => formatCurrency(String(value || ""))
    },
    {
      label: "Customer Bank Name", value: true, columnId: "customerBankName", width: getColumnWidth(1 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Clearance Date", value: true, columnId: "clearanceDate", width: getColumnWidth(1 / totalColumns, windowWidth), resizable: true, formatter: (value) => (value ? String(value ? formatDate(new Date(value)) : "") : "")
    },
    {
      label: "Bounce Date", value: true, columnId: "bounceDate", width: getColumnWidth(1 / totalColumns, windowWidth), resizable: true, formatter: (value) => (value ? String(value ? formatDate(new Date(value)) : "") : "")
    },
    {
      label: "Cheque Bounced Bank", value: true, columnId: "chequeBouncedBank", width: getColumnWidth(1 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Bounce Reason", value: true, columnId: "bounceReason", width: getColumnWidth(1 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    },
    {
      label: "Payment Receipt Description", value: true, columnId: "paymentReceiptDescription", width: getColumnWidth(1 / totalColumns, windowWidth), resizable: true, formatter: (value) => String(value || "")
    }
  ];

  return colArr;
}
