import { getSupplierAdvancePaymentRows, getSupplierAdvancePaymentColumns } from "./supplier-advance-payment-statement";
import { getSupplierAdvancePayment } from "@/api/finance/reportApi";
import {
  DynamicReport
} from "@/components/dynamic";

const supplierAdvancePaymentFilters = {
  SupplierKey: "*",
  PaymentNumber: "*",
  ExpenseNumber: "*",
};

const supplierAdvancePaymentFilterOptions = [
  { key: "supplierKey", apiKey: "SupplierKey" },
  { key: "paymentNumber", apiKey: "PaymentNumber", keyType: "string" },
  { key: "expenseNumber", apiKey: "ExpenseNumber", keyType: "string" },
];

export default function ReportCollection({ activeCompany }) {
  return (
    <DynamicReport
      reportName="suppliers-advance-payments-with-expense"
      activeCompany={activeCompany}
      columnsDataProvider={getSupplierAdvancePaymentColumns}
      rowsDataProvider={getSupplierAdvancePaymentRows}
      fetchData={getSupplierAdvancePayment}
      exportUrl="reports/export-query-report/suppliers-advance-payments-with-expense"
      filtersInitialState={supplierAdvancePaymentFilters}
      filterOptions={supplierAdvancePaymentFilterOptions}
      orderBy="AdvancePaymentNumber"
      showDateFilter
      showDateRangeFilter={false}
    />
  );
}
