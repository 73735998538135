import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { groupBy, range } from "lodash";
import { pascalize } from "humps";

import { formatCurrencyNumber, getTestId, formatDate } from "@/utils/helpers";
import { DynamicObjectSearchSelectField, Select } from "@/components/common";
import { getLedgerAccounts } from "@/api/finance/ledgerAccountApi";
import { getBudgetCategories } from "@/api/finance/budgetApi";
import { spreadSheetSelectStyles } from "@/utils/select/constants";
import { buildTree, flattenTree, formatBudgetCategory } from "@/utils/finance/helpers";
import { CustomActionDropdown } from "@/components/dynamic";

export default function ActionCell({
  onDelete, onEdit, row, objectName
}) {
  const actions = [
    {
      title: "View",
      icon: "eye",
    },
    {
      title: "Edit",
      onClick: () => { onEdit(row.original.id); },
      icon: "edit-icon",
      actionType: "Update"
    },
    {
      title: "Delete",
      onClick: () => { onDelete(row.original.id); },
      icon: "trash-icon",
      actionType: "Delete"
    },
  ];
  return (
    <div className="action-cell">
      <CustomActionDropdown
        actions={actions}
        trigger={(
          <div className="action-icon-trigger">
            <span className="material-icons-outlined">more_vert</span>
          </div>
        )}
        testId="Budget-Action-Button"
        objectName={objectName}
      />
    </div>
  );
}

ActionCell.propTypes = {
  row: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

function DateCell({ value }) {
  return (
    <div className="date-cell">
      {formatDate(new Date(value))}
    </div>
  );
}

DateCell.propTypes = {
  value: PropTypes.string.isRequired,
};

export const getBudgetListViewTableColumns = () => [
  {
    Header: "Name",
    accessor: "name",
    defaultCanSort: true,
    filter: "text"
  },
  {
    Header: "Fiscal Year",
    accessor: "fiscalYear",
    defaultCanSort: true,
  },
  {
    Header: "Created At",
    accessor: "createdAt",
    Cell: DateCell,
    defaultCanSort: true,
    collapse: true
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: ActionCell,
    collapse: true,
  },
];

export const getBudgetListViewTableData = (data) => data;

export const getBudgetCategoryTableColumns = () => {
  const columns = [
    {
      Header: "Name",
      accessor: "name",
      defaultCanSort: true,
    },
    {
      Header: "Code",
      accessor: "code",
    },
    {
      Header: "Type",
      accessor: "categoryType",
    },
    {
      Header: "Account",
      accessor: "account",
    },
    {
      Header: "Action",
      accessor: "action",
      Cell: ActionCell,
      collapse: true,
    },
  ];

  return columns;
};

export const getBudgetCategoryTableData = (data) => data.map((item) => ({
  id: item.id,
  name: item.name,
  code: item.code,
  account: item.account?.name,
  categoryType: item.categoryType
}));

function NumberInput({
  value, onChange, onBlur, disabled, testId
}) {
  const [isEditing, setIsEditing] = useState(false);

  const handleBlur = () => {
    setIsEditing(false);
    onBlur();
  };

  if (isEditing && !disabled) {
    return <input type="number" value={value} onChange={onChange} onBlur={handleBlur} min="0" />;
  }

  return (
    <input
      type="text"
      value={value ? formatCurrencyNumber(value) : ""}
      onFocus={() => setIsEditing(true)}
      readOnly
      data-testid={getTestId(testId)}
    />
  );
}

NumberInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  testId: PropTypes.string
};

NumberInput.defaultProps = {
  value: "",
  onChange: () => {},
  onBlur: () => {},
  disabled: false,
  testId: ""
};

function EditableCell({
  value: cellValue,
  row: { index, original },
  column: { id, disabled },
  setData,
}) {
  const initialValue = cellValue?.value;
  const [value, setValue] = useState(initialValue);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const onBlur = () => {
    if (value !== initialValue) {
      setData(index, id, value);
    }
  };

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  if (original.isParent) {
    return <div />;
  }

  return (
    <NumberInput
      value={value ? Number(value) : ""}
      onChange={onChange}
      onBlur={onBlur}
      disabled={disabled}
      data-testid={getTestId(`Budget-${pascalize(id)}-Input-${index}`)}
    />
  );
}

EditableCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  row: PropTypes.object.isRequired,
  column: PropTypes.object.isRequired,
  setData: PropTypes.func.isRequired,
};

EditableCell.defaultProps = {
  value: "",
};

function TotalCell({ value }) {
  return <div>{formatCurrencyNumber(value)}</div>;
}

TotalCell.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

TotalCell.defaultProps = {
  value: "",
};

const costCenterObjectNames = [
  {
    label: "Building",
    value: "Building",
    optionLabelKey: "name",
    isDynamic: true,
  },
  {
    label: "Unit",
    value: "Unit",
    optionLabelKey: "name",
    isDynamic: true,
  },
  {
    label: "Component",
    value: "Component",
    optionLabelKey: "name",
    isDynamic: true,
  },
  {
    label: "Location",
    value: "Location",
    optionLabelKey: "name",
    isDynamic: true,
  },
  {
    label: "Department",
    value: "Organization",
    optionLabelKey: "orgName",
    isDynamic: true,
  },
  {
    label: "Class",
    value: "Class",
    optionLabelKey: "name",
    isDynamic: true,
  },
];

function IndicatorSeparator() {
  return null;
}

function LedgerAccountCell({
  value, row: { index }, column: { id }, setData, isEditing
}) {
  const onChange = (option) => setData(index, id, option);

  return (
    <Select
      id={`Ledger-Account-${index}`}
      name="ledger-account"
      queryKey="ledger-account"
      placeholder="Select account"
      value={value}
      onChange={onChange}
      styles={spreadSheetSelectStyles}
      optionsLoader={() => getLedgerAccounts().then((res) => {
        res.data = flattenTree(buildTree(res.data, 1));
        return res;
      })}
      dataAccessKey="data"
      optionLabelKey="code"
      optionValueKey="id"
      isClearable
      isDisabled={isEditing}
      testId={getTestId(`Budget-${pascalize(id)}-Select-${index}`)}
      menuPortalTarget={document.body}
    />
  );
}

function BudgetCategoryCell({
  value, row: { index }, column: { id }, setData, isEditing
}) {
  const onChange = (option) => setData(index, id, option);

  return (
    <Select
      id={`Budget-Category-${index}`}
      name="budget-category"
      queryKey="budget-category"
      placeholder="Select category"
      value={value}
      onChange={onChange}
      styles={spreadSheetSelectStyles}
      optionsLoader={getBudgetCategories}
      formatOption={formatBudgetCategory}
      dataAccessKey="data"
      optionLabelKey="name"
      optionValueKey="id"
      hasNestedOptions
      isClearable
      isDisabled={isEditing}
      testId={getTestId(`Budget-${pascalize(id)}-Select-${index}`)}
      menuPortalTarget={document.body}
    />
  );
}

function CostCenterCell({
  value, row: { index }, column: { id }, setData, isEditing
}) {
  const onChange = (option) => setData(index, id, option);

  return (
    <DynamicObjectSearchSelectField
      objectNames={costCenterObjectNames}
      value={value}
      onChange={onChange}
      backspaceRemovesValue
      isClearable
      styles={spreadSheetSelectStyles}
      showLabel={false}
      components={{ IndicatorSeparator }}
      testId={getTestId(`Budget-${pascalize(id)}-Select-${index}`)}
      allowMultiple={false}
      disabled={isEditing}
    />
  );
}

export const getBudgetTableColumns = ({ periods, interval }) => {
  const columns = [
    {
      Header: "Sr #",
      accessor: "index"
    },
    {
      Header: "Ledger Account",
      accessor: "account",
      Cell: LedgerAccountCell
    },
    // {
    //   Header: "Budget Category",
    //   accessor: "budgetCategory",
    //   Cell: BudgetCategoryCell
    // },
    {
      Header: "Cost Center",
      accessor: "costCenter",
      Cell: CostCenterCell
    }
  ];

  if (!periods || !periods?.data || !interval) {
    return columns;
  }

  periods?.data?.forEach((period) => {
    const { number } = period;
    columns.push({
      Header: period.label,
      accessor: `p${number}`,
      Cell: EditableCell,
    });
  });

  if (interval.value !== "Yearly") {
    columns.push({
      Header: "Total",
      accessor: "total",
      Cell: TotalCell,
      disabled: true
    });
  }

  return columns;
};

export const getBudgetTableData = () => {
  const rows = [];
  const initialRows = range(20);
  initialRows.forEach((index) => {

    const row = {
      id: index,
      index: index + 1,
      account: null,
      budgetCategory: null,
      costCenter: {},
      total: "",
      depth: 0,
      isSelected: true
    };

    rows.push(row);
  });

  return rows;
};

export const opExAccountTypes = ["Income", "CostOfSales", "Expense", "OtherIncome", "OtherExpense"];
export const capExAccountTypes = [
  "CurrentAsset",
  "NonCurrentAsset",
  "CurrentLiabilities",
  "NonCurrentLiabilities",
  "OwnersEquity",
];

export const formatBudgetAccounts = (data, type) => {
  if (!data) return [];
  let accounts;

  const groupedAccounts = groupBy(data, "accountType");
  if (type === "opex") {
    accounts = opExAccountTypes.map((accountType) => ({
      accountType,
      accounts: groupedAccounts[accountType],
    }));
  } else {
    accounts = capExAccountTypes.map((accountType) => ({
      accountType,
      accounts: groupedAccounts[accountType],
    }));
  }

  return accounts;
};

export const calculateOpExTotals = (data) => {
  const totals = {};
  const groupedAccounts = groupBy(data, "accountType");

  opExAccountTypes.forEach((accountType) => {
    const accounts = groupedAccounts[accountType];
    const totalAmount = accounts.reduce(
      (total, currentValue) => total + Number(currentValue.total),
      0
    );

    totals[accountType] = totalAmount;
  });

  return totals;
};

export const calculateOpExOutput = (data) => {
  const totals = calculateOpExTotals(data);
  const {
    Income, CostOfSales, OtherIncome, Expense, OtherExpense
  } = totals;

  return {
    Income,
    CostOfSales,
    Expense,
    OtherIncome,
    OtherExpense,
  };
};

export const calculateCapExTotals = (data) => {
  const totals = {};
  const groupedAccounts = groupBy(data, "accountType");

  capExAccountTypes.forEach((accountType) => {
    const accounts = groupedAccounts[accountType];
    const totalAmount = accounts.reduce(
      (total, currentValue) => total + Number(currentValue.total),
      0
    );

    totals[accountType] = totalAmount;
  });

  return totals;
};

export const calculateCapExOutput = (data) => {
  const totals = calculateCapExTotals(data);
  const {
    CurrentAsset, NonCurrentAsset, CurrentLiabilities, NonCurrentLiabilities, OwnersEquity
  } =
    totals;

  return {
    Assets: CurrentAsset + NonCurrentAsset,
    Liabilities: CurrentLiabilities + NonCurrentLiabilities,
    Equities: OwnersEquity,
  };
};

export const calculateOutputVariables = (data, type) => {
  if (type === "opex") {
    return calculateOpExOutput(data);
  }

  return calculateCapExOutput(data);
};

export const formatBudgetCategories = (data, type) => {
  if (!data) return [];

  let categories = [];

  if (type === "opex") {
    categories = data.filter((c) => c.categoryType === "Opex");
  } else {
    categories = data.filter((c) => c.categoryType === "Capex");
  }

  return categories;
};
