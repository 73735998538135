import { getCollectChequeRows, getCollectChequeColumns } from "./collect-cheque-statement";
import { getCollectCheque } from "@/api/finance/reportApi";
import {
  DynamicReport
} from "@/components/dynamic";

const collectChequeFilters = {
  TenantName: "*",
  TenantKey: "*",
  ComponentName: "*",
  ComponentKey: "*",
  BankName: "*",
  BankKey: "*",
  ClearedBankName: "*",
  ClearedBankCode: "*",
};

const collectFilterOptions = [
  { key: "tenantName", apiKey: "TenantName", keyType: "label" },
  { key: "tenantKey", apiKey: "TenantKey", keyType: "string" },
  { key: "componentName", apiKey: "ComponentName", keyType: "string" },
  { key: "componentKey", apiKey: "ComponentKey", keyType: "string" },
  { key: "bankName", apiKey: "BankName", keyType: "string" },
  { key: "bankKey", apiKey: "BankKey", keyType: "string" },
  { key: "clearedBankName", apiKey: "ClearedBankName", keyType: "string" },
  { key: "clearedBankCode", apiKey: "ClearedBankCode", keyType: "string" },
];

export default function ReportCollectCheque({ activeCompany }) {
  return (
    <DynamicReport
      reportName="clear-cheque"
      activeCompany={activeCompany}
      columnsDataProvider={getCollectChequeColumns}
      rowsDataProvider={getCollectChequeRows}
      fetchData={getCollectCheque}
      exportUrl="reports/export-query-report/collect-cheque"
      filtersInitialState={collectChequeFilters}
      showDateFilter
      showDateRangeFilter={false}
      filterOptions={collectFilterOptions}
      orderBy="TenantName"
    />
  );
}
