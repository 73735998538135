import PropTypes from "prop-types";
import { StatusCircle, Status, } from "@/components/common";
import { statusColorMap } from "@/utils/maps/statusColorMap";
import { CustomActionDropdown } from "@/components/dynamic";
import { formatDate } from "@/utils/helpers";

function StatusCell({ value }) {
  return (
    <Status
      baseClassName="status-cell"
      status={value}
      bgColor={statusColorMap.get(value?.toLowerCase())}
    >
      <StatusCircle color={statusColorMap.get(value?.toLowerCase())} />
    </Status>
  );
}

StatusCell.propTypes = {
  value: PropTypes.string.isRequired,
};

export default function ActionCell({
  onEdit, row, onView, objectName
}) {
  const getActions = () => {
    const actions = [
      {
        title: "View",
        onClick: () => { onView(row.original.id); },
        icon: "eye",
        actionType: "View"
      },
      {
        title: "Edit",
        onClick: () => { onEdit(row.original.id); },
        icon: "edit-icon",
        actionType: "Update"
      }
    ];
    return actions;
  };

  return (
    <div className="action-cell">
      <CustomActionDropdown
        actions={getActions()}
        trigger={(
          <div className="action-icon-trigger">
            <span className="material-icons-outlined">more_vert</span>
          </div>
        )}
        testId="Accounting-Period-Action-Button"
        objectName={objectName}
      />
    </div>
  );
}

ActionCell.propTypes = {
  row: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

function DateCell({ value }) {
  return (
    <div>
      {formatDate(new Date(value))}
    </div>
  );
}

DateCell.propTypes = {
  value: PropTypes.string.isRequired,
};

function ObjectValueCell({
  value
}) {
  return (
    <div>
      {value?.name}
    </div>
  );
}

ObjectValueCell.propTypes = {
  value: PropTypes.object.isRequired,
};

function CreatedAtDateCell({ value }) {
  return (
    <div className="date-cell">
      {formatDate(new Date(value))}
    </div>
  );
}

CreatedAtDateCell.propTypes = {
  value: PropTypes.string.isRequired,
};

export const getAccountingPeriodTableColumns = () => [
  {
    Header: "Name",
    accessor: "name",
    defaultCanSort: true,
    filter: "text"
  },
  {
    Header: "Company",
    accessor: "companyName",
    cell: ObjectValueCell
  },
  {
    Header: "Start Date",
    accessor: "startDate",
    Cell: DateCell
  },
  {
    Header: "End Date",
    accessor: "endDate",
    Cell: DateCell
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: StatusCell
  },
  {
    Header: "Created At",
    accessor: "createdAt",
    Cell: CreatedAtDateCell,
    defaultCanSort: true,
    collapse: true
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: ActionCell,
    collapse: true,
  },
];

export const getAccountingPeriodTableData = (data) => data.map((row) => ({
  ...row,
  action: ""
}));
