import React from "react";
import { useTable, useFilters, useSortBy } from "react-table";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { kebabCase } from "lodash";

import { SortableArrows } from "@/components/common";
import { AlertModal } from "@/components/modals";
import { useModal } from "@/hooks";

function Table({
  columns: userColumns, data, handleDelete, objectName
}) {
  const [selectedId, setSelectedId] = React.useState(null);
  const { isOpen, closeModal, openModal } = useModal(false);
  const navigate = useNavigate();
  const {
    getTableProps, getTableBodyProps, headerGroups, rows, prepareRow
  } =
    useTable(
      {
        columns: userColumns,
        data,
        onDelete: (id) => {
          openModal();
          setSelectedId(id);
        },
        onEdit: (id) => {
          navigate(`/finance/${kebabCase(objectName)}/${id}`);
        },
        objectName,
      },
      useFilters,
      useSortBy
    );

  const handleRowClick = (row) => {

    if (objectName === "Budget") {
      navigate(row.original.id);
    }
  };

  return (
    <>
      <table className="table" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr
              className="table-header-row"
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column) => (
                <th
                  className={`table-header-cell ${
                    column.collapse ? "collapse" : ""
                  }`}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  <div className="table-header-cell-content">
                    {column.render("Header")}
                    {column.defaultCanSort ? (
                      <SortableArrows
                        isSorted={column.isSorted}
                        isSortedDesc={column.isSortedDesc}
                      />
                    ) : null}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr onClick={() => { handleRowClick(row); }} className="table-row" {...row.getRowProps()}>
                {row.cells.map((cell) => (
                  <td
                    className={`table-body-cell ${
                      cell.column.collapse ? "collapse" : ""
                    }`}
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>

      <AlertModal
        onClose={closeModal}
        isOpen={isOpen}
        onConfirm={() => {
          handleDelete(selectedId);
          closeModal();
        }}
      />
    </>
  );
}

function FinanceMasterTable({
  tableColumns, data, isLoading, handleDelete, objectName
}) {
  const columns = React.useMemo(() => (isLoading ?
    tableColumns.map((column) => ({
      ...column,
      Cell: Skeleton,
    })) :
    tableColumns), [isLoading, tableColumns]);
  const rows = React.useMemo(() => data, [data]);

  return (
    <div className="table-wrapper">
      <Table columns={columns} data={rows} handleDelete={handleDelete} objectName={objectName} />
    </div>
  );
}

export default FinanceMasterTable;
