const dynamicObjectMap = new Map([
  // Facility Management
  ["ServiceRequestObjectName", "ServiceRequest"],
  ["BlanketAgreementObjectName", "BlanketAgreement"],
  ["ServiceRequestNotesObjectName", "ServiceRequestNotes"],
  ["TechnicianSkillObjectName", "TechnicianSkill"],
  ["WorkOrderObjectName", "WorkOrder"],
  ["WorkOrderJobObjectName", "WorkOrderJob"],
  ["JobMaterialObjectName", "WorkOrderJobMaterials"],
  ["JobAssetObjectName", "WorkOrderJobAssets"],
  ["ServiceRequestDashboardObjectName", "ServiceRequest"],
  ["SupplierStaffObjectName", "SupplierStaff"],
  ["WorkOrderJobProposedTime", "WorkOrderJobProposedTime"],
  ["FitOutRequestObjectName", "FitOutRequest"],
  ["VisualInspectionObjectName", "VisualInspection"],
  ["IncidentReportObjectName", "IncidentReport"],
  ["PettyCashObjectName", "PettyCash"],
  ["PettyCashRequestObjectName", "PettyCashRequest"],
  ["DesignationObjectName", "Designation"],
  ["ProformaInvoiceObjectName", "ProformaInvoice"],
  ["SupervisorPettyCashRequestObjectName", "SupervisorPettyCashRequest"],
  ["DepartmentObjectName", "Department"],
  ["MaterialRequestObjectName", "MaterialRequest"],
  ["PettyCashClaimObjectName", "PettyCashClaim"],
  ["PettyCashTransactionObjectName", "PettyCash_Transaction"],
  ["BlanketAgreementPaymentDetailObjectName", "BlanketAgreementPaymentDetail"],
  ["EntityObjectName", "Entity"],

  // Masters
  ["UnitObjectName", "Unit"],
  ["BuildingObjectName", "Building"],
  ["TenantObjectName", "Tenant"],
  ["ItemObjectName", "Item"],
  ["AssetClassObjectName", "AssetClass"],
  ["EmployeeObjectName", "Employee"],
  ["LocationObjectName", "Location"],
  ["OrganizationObjectName", "Organization"],
  ["ClassObjectName", "Class"],
  ["CategoryObjectName", "Category"],

  // Leasing
  ["ContractObjectName", "Contract"],
  ["ContractPaymentDetailObjectName", "ContractPaymentDetail"],
  ["MoveInRequestObjectName", "MoveInRequest"],
  ["MoveInCheckListObjectName", "MoveInCheckList"],
  ["MoveOutRequestObjectName", "MoveOutRequest"],
  ["CarParkingObjectName", "CarParking"],
  ["ReservationObjectName", "Reservation"],
  ["CustomerQuotationObjectName", "CustomerQuotation"],
  ["QuotationObjectName", "Quotation"],
  ["ComponentObjectName", "Component"],
  ["FloorObjectName", "Floor"],
  ["AmenityObjectName", "Amenity"],
  ["FacilityObjectName", "Facility"],
  ["RevenueRecognitionObjectName", "RevenueRecognition"],
  ["RevenueRecognitionRevenueRecognitionDetailObjectName", "RevenueRecognitionRevenueRecognitionDetail"],
  ["LeadObjectName", "Lead"],
  ["BlanketAgreementObjectName", "BlanketAgreement"],
  ["SupplierObjectName", "Supplier"],
  ["LandlordObjectName", "Landlord"],
  ["LandObjectName", "Land"],
  ["ContractTerminationObjectName", "ContractTermination"],
  ["FacilityContractObjectName", "FacilityContract"],
  ["BankAccountObjectName", "BankAccount"],
  ["LegalCaseObjectName", "LegalCase"],
  ["ContractUnitAnnualAmountObjectName", "ContractUnitAnnualAmount"],

  // Sales
  ["AccountObjectName", "Account"],
  ["OpportunityObjectName", "Opportunity"],
  ["LeadObjectName", "Lead"],
  ["ContactObjectName", "Contact"],
  ["CustomerObjectName", "Customer"],

  // Finance
  ["PurchaseOrderObjectName", "PurchaseOrder"],
  ["PurchaseOrderItemObjectName", "PurchaseOrderItemDetail"],
  ["PurchaseInvoiceObjectName", "PurchaseInvoice"],
  ["ProformaInvoiceObjectName", "ProformaInvoice"],
  ["PettyCashRequestObjectName", "PettyCashRequest"],
  ["PettyCashTransactionObjectName", "PettyCashTransaction"],
  ["WarehouseObjectName", "Warehouse"],
  ["GoodsReceiptNoteObjectName", "GoodsReceiptNote"],
  ["GoodsReceiptNoteItemObjectName", "GoodsReceiptNoteItemDetail"],
  ["RequestForQuotationObjectName", "RequestForQuotation"],
  ["PaymentReceiptObjectName", "PaymentReceipt"],
  ["PaymentReceiptOutstandingTransactionObjectName", "PaymentReceiptOutstandingTransaction"],
  ["RentalInvoiceObjectName", "RentalInvoice"],
  ["CreditNoteObjectName", "CreditNote"],
  ["StockTransferObjectName", "StockTransfer"],
  ["BounceChequeObjectName", "BounceCheque"],
  ["GeneralInvoiceObjectName", "GeneralInvoice"],
  ["VendorClassObjectName", "VendorClass"],
  ["ClearChequeObjectName", "ClearCheque"],
  ["WarehouseChequeObjectName", "WarehouseCheque"],
  ["GeneralReceiptObjectName", "GeneralReceipt"],
  ["ReceiptObjectName", "Receipt"],
  ["BulkReceiptObjectName", "BulkReceipt"],
  ["BulkInvoiceObjectName", "BulkInvoice"],
  ["PaymentObjectName", "Payment"],
  ["AmcInvoiceObjectName", "AmcInvoice"],
  ["FixedAssetCreationRequestObjectName", "FixedAssetCreationRequest"],
  ["AssetTransferRequestObjectName", "AssetTransferRequest"],
  ["DepreciationObjectName", "Depreciation"],
  ["DepreciationAssetDetailObjectName", "DepreciationAssetDetail"],
  ["RevaluationObjectName", "Revaluation"],
  ["RevaluationAssetDetailObjectName", "RevaluationAssetDetail"],
  ["DisposalObjectName", "Disposal"],
  ["SupplierCreditNoteObjectName", "SupplierCreditNote"],
  ["ExpenseObjectName", "Expense"],
  ["ExpenseTypeObjectName", "ExpenseType"],
  ["PDCObjectName", "PDC"],
  ["ExpenseRecognitionObjectName", "ExpenseRecognition"],
  ["ExpenseDetailObjectName", "ExpenseExpenseDetail"],
  ["ExpenseRecognitionExpenseRecognitionDetailObjectName", "ExpenseRecognitionExpenseRecognitionDetail"],
  ["PettyCashClaimTransactionObjectName", "PettyCashClaimTransaction"],
  ["RecallChequeObjectName", "RecallCheque"],
]);

export default dynamicObjectMap;
