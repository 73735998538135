import PropTypes from "prop-types";
import { startCase } from "lodash";
import { format } from "date-fns";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import appSettings from "@/settings";
import { selectActiveCompany } from "@/store/appSlice";
import { CustomActionDropdown } from "@/components/dynamic";
import { formatDate } from "@/utils/helpers";

function ActionCell({ row, objectName }) {
  const activeCompany = useSelector(selectActiveCompany);
  const navigate = useNavigate();
  const filteredActions = () => {
    const { baseUrl } = appSettings;
    const actions = [
      {
        title: "View",
        onClick: () => { navigate(`${row.original.key}`); },
        icon: "eye",
      },
      {
        title: "Print",
        onClick: () => {
          window.open(`${baseUrl}/reports/journal-entry?Number=${row.original.number}&CompanyId=${activeCompany.id}`, "_blank");
        },
        icon: "printer-icon",
        actionType: "Print"
      },
      {
        title: "Clone",
        onClick: () => navigate(`/finance/journal-entry/new?journal-entry=${row.original.key}`),
        icon: "upload-cloud-stroke-icon",
        actionType: "Clone"

      },
      {
        title: "Reversal",
        onClick: () => navigate(`/finance/journal-entry/new?journal-entry=${row.original.key}&reversal=true`),
        icon: "edit-icon",
        actionType: "Reversal"

      },
    ];
    return actions;
  };

  return (
    <div className="action-cell">
      <CustomActionDropdown
        actions={filteredActions()}
        trigger={(
          <div className="action-icon-trigger">
            <span className="material-icons-outlined">more_vert</span>
          </div>
        )}
        testId="Jobs-Table-Action-Dropdown"
        objectName={objectName}
      />
    </div>
  );
}

ActionCell.propTypes = {
  row: PropTypes.object.isRequired,
};

function LinkCell({ row }) {
  return (
    <div className="link-cell">
      <Link to={`${row.original.key}`}>{row.original.name}</Link>
    </div>
  );
}

LinkCell.propTypes = {
  row: PropTypes.object.isRequired,
};

function CreatedByCell({ value }) {
  return (
    <div className="date-cell">{value ? startCase(value) : ""}</div>
  );
}

CreatedByCell.propTypes = {
  value: PropTypes.string.isRequired,
};

function DateCell({ value }) {
  return (
    <div className="date-cell">{`${formatDate(new Date(value))} ${format(new Date(`${value}Z`), "hh:mm a")}`}</div>
  );
}

DateCell.propTypes = {
  value: PropTypes.string.isRequired,
};

export const getJournalEntryColumns = () => [
  {
    Header: "Number",
    accessor: "number",
  },
  {
    Header: "Company",
    accessor: "companyName",
  },
  {
    Header: "Type",
    accessor: "type",
  },
  {
    Header: "Created By",
    accessor: "createdByName",
    Cell: CreatedByCell,
  },
  {
    Header: "Created At",
    accessor: "createdAt",
    Cell: DateCell,
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: ActionCell,
    collapse: true
  }
];

export const getJournalEntryData = (data) => data.map((dynamicObject) => ({
  key: dynamicObject.id,
  companyName: dynamicObject.companyName,
  number: dynamicObject.number,
  type: dynamicObject.type,
  createdByName: dynamicObject.createdByName,
  createdAt: dynamicObject.createdAt,
  action: "",
}));
