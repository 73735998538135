import { useNavigate } from "react-router-dom";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";

import { Button } from "@hydra/atom/components";
import { useSelector } from "react-redux";
import { BoxedContent, Header, NoDataFound } from "@/components/common";
import { FinanceMasterTable } from "@/components/finance";
import {
  getAccountingPeriodTableColumns,
  getAccountingPeriodTableData,
} from "@/components/finance/accounting-period/accountingPeriodTableData";
import { getAccountingPeriods, deleteAccountingPeriod } from "@/api/finance/accountingPeriodApi";
import { skeletonLoadingRows } from "@/utils/helpers";
import showToast from "@/utils/toast/helpers";
import { selectPermission } from "@/store/userSlice";
import staticObjectMap from "@/utils/maps/staticObjectMap";

function AccountingPeriod() {
  const navigate = useNavigate();
  const hasPermission = useSelector(selectPermission({ parent: "Model", scope: staticObjectMap.get("AccountingPeriodObjectName"), action: "Insert" }));
  const { data, isLoading } = useQuery(["accounting-period"], getAccountingPeriods);

  const queryClient = useQueryClient();
  const deleteMutation = useMutation(
    deleteAccountingPeriod,
    {
      onError: () => {
        showToast("Could not delete. Try again!", "error");
      },
      onSuccess: () => {
        showToast("Deleted successfully", "success");
        queryClient.invalidateQueries({
          queryKey: ["accounting-period"]
        });
      },
    }
  );

  const renderTable = () => {
    if (!isLoading && data && !data?.data) {
      return (
        <NoDataFound
          title="No accounting periods have been defined"
          buttonText="Create Accounting Period"
          onClick={() => navigate("/finance/accounting-period/new")}
        />
      );
    }
    return (
      <FinanceMasterTable
        objectName="AccountingPeriod"
        isLoading={isLoading}
        tableColumns={getAccountingPeriodTableColumns(data?.data || [])}
        data={getAccountingPeriodTableData(data?.data || skeletonLoadingRows(5))}
        handleDelete={(id) => deleteMutation.mutate(id)}
      />
    );
  };

  return (
    <BoxedContent>
      <Header
        showBreadcrumb
        leftContent={<h1>Accounting Period</h1>}
        rightContent={hasPermission ? (
          <Button small onClick={() => navigate("/finance/accounting-period/new")}>
            Create Accounting Period
          </Button>
        ) : ""}
      />

      {renderTable()}
    </BoxedContent>
  );
}

export default AccountingPeriod;
