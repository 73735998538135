import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import DynamicObjectOverview from "@/pages/dynamic/list-views/DynamicObjectOverview";

function QuotationOverview() {
  return (
    <DynamicObjectOverview
      objectName={dynamicObjectMap.get("QuotationObjectName")}
      showFilters
      showActionButton={false}
      noDataHideButton
    />
  );
}

export default QuotationOverview;
