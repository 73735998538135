import {
  useState, useRef, useMemo, useEffect
} from "react";
import { ReactGrid } from "@silevis/reactgrid";
import { Input, Button } from "@hydra/atom/components";
import { useQuery } from "@tanstack/react-query";
import { SvgIcon, Select, IconButton } from "@/components/common";
import { getCompanies } from "@/api/finance/companyApi";
import { getBalanceSheetRows, getBalanceSheetColumns } from "./balance-sheet";
import { ReportFilterDrawer } from "../../drawers";
import { getAccountingPeriods } from "@/api/finance/accountingPeriodApi";
import { getBalanceSheet } from "@/api/finance/reportApi";
import { buildTree, getExpandedRows } from "./cells";
import TableSkeleton from "@/components/common/TableSkeleton";
import appSettings from "@/settings";

function ReportSheetBalanceSheet({
  accountingPeriod: accountingPeriodData, activeCompany
}) {
  const ref = useRef();
  const [filterText, setFilterText] = useState("");
  const [filter, setFilter] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [rowsExpanded, setRowExpanded] = useState([]);
  const [accountingPeriod, setAccountingPeriod] = useState(null);
  const [filters, setFilters] = useState({});
  const onClose = (value) => {
    setIsOpen(value);
  };

  useEffect(() => {
    setFilter({ ...filter, company: { label: activeCompany.name, value: activeCompany.id } });
  }, [activeCompany]);

  useEffect(() => {
    setAccountingPeriod(accountingPeriodData);
  }, [accountingPeriodData]);

  const exportCsv = () => window.open(`${appSettings.baseUrl}/finance/reports/balance-sheet/csv?CompanyId=${filter?.company?.value}`, "_blank", "noopener,noreferrer");

  const { data: getBalanceSheetData, isLoading } = useQuery(["balance-sheet", accountingPeriod?.value, filter.company?.value],
    () => getBalanceSheet({
      accountingPeriodId: accountingPeriod?.value,
      companyId: filter.company.id ? filter.company.id : activeCompany.id
    }),
    {
      enabled: Boolean(accountingPeriod?.value) && Boolean(filter?.company?.value)
    });

  const columns = useMemo(
    () => getBalanceSheetColumns(accountingPeriod),
    [accountingPeriod]
  );

  useEffect(() => {
    setRows(buildTree(getBalanceSheetRows(
      {
        data: {
          data: getBalanceSheetData?.data || [],
        },
        columns,
      }
    )));
  }, [getBalanceSheetData, accountingPeriod]);

  useEffect(() => {
    setRowExpanded(getExpandedRows(rows));
  }, [rows]);

  const handleChanges = (changes) => {
    const newRows = [...rows];
    changes.forEach((change) => {
      const changeRowIdx = rows.findIndex((el) => el.rowId === change.rowId);
      const changeColumnIdx = columns.findIndex(
        (el) => el.columnId === change.columnId
      );
      newRows[changeRowIdx].cells[changeColumnIdx] = change.newCell;
    });
    setRows(buildTree(newRows));
    setRowExpanded(getExpandedRows(newRows));
  };

  return (
    <div className="table-wrapper report-sheet" ref={ref}>
      <div className="row filter-row">
        <div className="col-md-2">
          <Input
            className="input-height-fix"
            value={filterText}
            onChange={setFilterText}
            placeholder="Search by name"
          />
        </div>
        <div className="col-md-2">
          <Select
            id="accountPeriod"
            name="accountPeriod"
            className="input-height-fix"
            queryKey="accounting-period"
            value={accountingPeriod}
            onChange={(value) => { setAccountingPeriod(value); }}
            optionsLoader={getAccountingPeriods}
            dataAccessKey="data"
            optionLabelKey="name"
            optionValueKey="id"
            isClearable
            backspaceRemovesValue
            rules="required"
          />
        </div>
        <div className="col-md-2">
          <Select
            name="company"
            queryKey="company"
            className="input-height-fix"
            placeholder="Select company"
            value={filter.company}
            onChange={(value) => setFilter({ ...filter, company: value })}
            optionsLoader={() => getCompanies()}
            dataAccessKey="data"
            optionLabelKey="name"
            optionValueKey="id"
            isClearable
            backspaceRemovesValue
          />
        </div>
        <div className="col-md-4 offset-2 btn-icon-container">
          <IconButton
            className="icon-button-with-text"
            onClick={exportCsv}
          >
            <SvgIcon icon="excel" />
            <span>Download Excel</span>
          </IconButton>
          <Button
            onClick={() => setIsOpen(true)}
            small
            bordered
          >
            <Button.Prepend>
              <SvgIcon icon="filter-lines" />
            </Button.Prepend>
          </Button>
          <ReportFilterDrawer
            filters={filters}
            setFilters={setFilters}
            size={400}
            isOpen={isOpen}
            onClose={onClose}
          />
        </div>

      </div>
      <div className="react-grid-container">
        {!isLoading ? (
          <ReactGrid
            key={rowsExpanded}
            rows={rowsExpanded}
            columns={columns}
            onCellsChanged={handleChanges}
            enableFillHandle
            enableRangeSelection
          />
        ) : <TableSkeleton /> }
      </div>
    </div>
  );
}

export default ReportSheetBalanceSheet;
