import { getBounceChequeRows, getBounceChequeColumns } from "./bounce-cheque-statement";
import { getBounceCheque } from "@/api/finance/reportApi";
import {
  DynamicReport
} from "@/components/dynamic";

const bounceChequeFilters = {
  TenantName: "*",
  TenantNumber: "*",
  ComponentName: "*",
  ComponentNumber: "*",
  PdcNumber: "*",
  UnitNumber: "*",
  ContractNumber: "*",
  TenantType: "*",
  BouncedReason: "*",
  ChequeBouncedBank: "*",
  PaymentReceiptNumber: "*",
  ChequeNo: "*",
};

const bounceChequeFilterOptions = [
  { key: "tenantName", apiKey: "TenantName", keyType: "label" },
  { key: "tenantNumber", apiKey: "TenantNumber", keyType: "string" },
  { key: "componentName", apiKey: "ComponentName", keyType: "label" },
  { key: "componentNumber", apiKey: "ComponentNumber", keyType: "number" },
  { key: "chequeAmountFrom", apiKey: "ChequeAmountFrom", keyType: "string" },
  { key: "chequeAmountTo", apiKey: "ChequeAmountTo", keyType: "string" },
  { key: "pdcNumber", apiKey: "PdcNumber", keyType: "string" },
  { key: "unitNumber", apiKey: "UnitNumber", keyType: "string" },
  { key: "contractNumber", apiKey: "ContractNumber", keyType: "label" },
  { key: "tenantType", apiKey: "TenantType" },
  { key: "bouncedReason", apiKey: "BouncedReason" },
  { key: "chequeBouncedBank", apiKey: "ChequeBouncedBank", keyType: "string" },
  { key: "paymentReceiptNumber", apiKey: "PaymentReceiptNumber", keyType: "label" },
  { key: "chequeNo", apiKey: "ChequeNo", keyType: "string" },
  { key: "pdcStartDate", apiKey: "PdcStartDate", keyType: "date" },
  { key: "pdcEndDate", apiKey: "PdcEndDate", keyType: "date" },
];

export default function ReportBounceCheque({ activeCompany }) {
  return (
    <DynamicReport
      reportName="bounce-cheque"
      activeCompany={activeCompany}
      columnsDataProvider={getBounceChequeColumns}
      rowsDataProvider={getBounceChequeRows}
      fetchData={getBounceCheque}
      exportUrl="reports/export-query-report/bounce-cheque"
      filtersInitialState={bounceChequeFilters}
      filterOptions={bounceChequeFilterOptions}
      orderBy="CustomerName"
    />
  );
}
