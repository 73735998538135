import PropTypes from "prop-types";
import * as Dropdown from "@radix-ui/react-dropdown-menu";
import { useSelector } from "react-redux";
import { pascalize } from "humps";
import { getTestId } from "@/utils/helpers";
import { useModal } from "@/hooks";
import { SvgIcon } from "@/components/common";
import { selectPermission } from "@/store/userSlice";
import staticObjectMap from "@/utils/maps/staticObjectMap";

function ActionDropdownItem({ action, onClick, testId }) {
  return (
    <Dropdown.Item
      className={`dropdown-menu-item action-menu-item ${action.title === "Delete" ? "action-menu-delete-item" : ""}`}
      onSelect={onClick}
      data-testid={getTestId(`${testId}-${action.title}-Button`)}
    >
      {action?.icon && <SvgIcon icon={action.icon} />}
      <a>{action.title}</a>
    </Dropdown.Item>
  );
}

ActionDropdownItem.propTypes = {
  action: PropTypes.shape({
    title: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  testId: PropTypes.string,
};

ActionDropdownItem.defaultProps = {
  testId: "",
};

function ActionDropdownContent({ actions, testId }) {
  return (
    <Dropdown.Content className="dropdown-menu-content action-dropdown-menu" side="bottom" align="end">
      {actions.map((action, index) => (
        <ActionDropdownItem key={`action-${index}`} action={action} onClick={action.onClick} testId={testId} />
      ))}
    </Dropdown.Content>
  );
}

ActionDropdownContent.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ).isRequired,
  testId: PropTypes.string,
};

ActionDropdownContent.defaultProps = {
  testId: "",
};

export function CustomActionDropdown({
  actions, trigger, testId, objectName
}) {
  const {
    open: openDropdown,
  } = useModal(false);
  const order = ["View", "Edit", "Delete", "Print"];
  const permissions = useSelector((state) =>
    actions.reduce((acc, item) => {

      if (item.actionType) {
        if (pascalize(objectName) === staticObjectMap.get("JournalEntryObjectName")) {
          acc[item.actionType] = selectPermission({ parent: "Report", scope: objectName, action: item.actionType })(state);
        } else {
          acc[item.actionType] = selectPermission({ parent: "Model", scope: objectName, action: item.actionType })(state);

        }
      }
      return acc;
    }, {})
  );

  const filteredActions = actions.filter(
    (item) => (objectName && item.actionType ? permissions[item.actionType] : item)
  );

  const sortedActions = filteredActions.sort((a, b) => {
    const indexA = order.indexOf(a.title);
    const indexB = order.indexOf(b.title);
    return indexA - indexB;
  });

  if (filteredActions.length < 1) {
    return;
  }

  return (
    actions?.length > 1 ? (
      <Dropdown.Root data-testid={getTestId(testId)}>
        <Dropdown.Trigger asChild data-testid={getTestId(`${testId}-Button`)} onClick={openDropdown}>
          {trigger}
        </Dropdown.Trigger>
        <Dropdown.Portal>
          <ActionDropdownContent actions={sortedActions} testId={testId} />
        </Dropdown.Portal>
      </Dropdown.Root>
    ) : (
      <div className="single-action-icon" role="presentation" onClick={sortedActions[0].onClick} data-testid={getTestId(testId)}>
        <SvgIcon icon={sortedActions[0].icon} />
      </div>
    )
  );
}

CustomActionDropdown.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      icon: PropTypes.string.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ).isRequired,
  trigger: PropTypes.node.isRequired,
};

export default CustomActionDropdown;
