import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { CustomActionDropdown } from "@/components/dynamic";
import { SvgIcon } from "@/components/common";
import { selectPermission } from "@/store/userSlice";
import { formatDate } from "@/utils/helpers";

function ActionDropDownCell({
  onDelete, onEdit, row, onView, objectName
}) {
  const getActions = () => {
    const actions = [
      {
        title: "View",
        onClick: () => { onView(row.original.id); },
        icon: "eye"
      },
      {
        title: "Edit",
        onClick: () => { onEdit(row.original.id); },
        icon: "edit-icon",
        actionType: "Update"
      }
    ];

    // if (deletePermission) {
    //   actions.push(
    //     {
    //       title: "Delete",
    //       onClick: () => { onDelete(row.original.id); },
    //       icon: "trash-icon"
    //     });
    // }
    return actions;
  };

  return (
    <div className="action-cell">
      <CustomActionDropdown
        actions={getActions()}
        trigger={(
          <div className="action-icon-trigger">
            <span className="material-icons-outlined">more_vert</span>
          </div>
        )}
        testId="Tax-Rule-Action-Button"
        objectName={objectName}
      />
    </div>
  );
}

ActionDropDownCell.propTypes = {
  row: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
};

function ObjectValueCell({
  value
}) {
  return (
    <div>
      {value?.name}
    </div>
  );
}

ObjectValueCell.propTypes = {
  value: PropTypes.object.isRequired,
};

function StatusCell({ row }) {
  return (
    <div className={`status-cell ${row?.original?.isFrozen ? "red" : "green"}`}>
      <div className="dot" />
      {row.original.isFrozen ? "Frozen" : "Active"}
    </div>
  );
}

StatusCell.propTypes = {
  row: PropTypes.object.isRequired,
};

function DateCell({ value }) {
  return (
    <div className="date-cell">
      {formatDate(new Date(value))}
    </div>
  );
}

DateCell.propTypes = {
  value: PropTypes.string.isRequired,
};

export const getTaxRuleTableColumns = () => [
  {
    id: "expander",
    Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
      <span {...getToggleAllRowsExpandedProps()}>
        {isAllRowsExpanded ? (
          <span className="material-icons-outlined">expand_more</span>
        ) : (
          <span className="material-icons-outlined">chevron_right</span>
        )}
      </span>
    ),
    Cell: ({ row }) => (row.canExpand ? (
      <span {...row.getToggleRowExpandedProps()}>
        {row.isExpanded ? (
          <span className="material-icons-outlined">expand_more</span>
        ) : (
          <span className="material-icons-outlined">chevron_right</span>
        )}
      </span>
    ) : null),
    collapse: true,
  },
  {
    Header: "Name",
    accessor: "name",
    Cell: ({ row, value }) => (
      <div
        className="code-cell"
        {...row.getToggleRowExpandedProps({
          style: {
            paddingLeft: `${row.depth * 2}rem`,
          },
        })}
      >
        <SvgIcon icon="folder-icon" />
        {value}
      </div>
    ),
    defaultCanSort: true,
    filter: "text"
  },
  {
    Header: "Tax Type",
    accessor: "taxType",
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: ActionDropDownCell,
    collapse: true,
  },
];

export const getTaxRuleTableData = (data) => data.map((row) => ({
  ...row,
  action: ""
}));
