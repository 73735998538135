import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@tanstack/react-query";
import { kebabCase } from "lodash";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import { DynamicFormContainer } from "@/components/dynamic";
import { formatDecimalValues } from "@/utils/helpers";
import { getDynamicObjectRecords } from "@/api/dynamic/dynamicObjectNameApi";
import { TASK_TYPES } from "@/components/workflow/constants";

function PettyCashClaimWorkflowFormContainer({
  task, recordData, setIsLoading, onSuccess
}) {
  const ref = useRef(null);
  const [initialState, setInitialState] = useState({});

  const { data: supervisorBuildings } = useQuery(
    [kebabCase(dynamicObjectMap.get("BuildingObjectName")), recordData?.supervisor],
    () =>
      getDynamicObjectRecords(
        kebabCase(dynamicObjectMap.get("BuildingObjectName")),
        {
          supervisor: recordData?.supervisor,
          takePage: 1,
          limitPage: 1,
          sortBy: "CreatedAt",
          sortType: "DESC",
          queryMode: "Deep",
        }
      ),
    {
      enabled: task?.currentStep === "Request for Petty Cash Claim",
    }
  );

  useEffect(() => {
    const data = {
      totalApproved: recordData?.total
    };

    if (recordData?.transaction?.length > 0) {
      const totalApproved = recordData?.transaction?.reduce((sum, transaction) => {
        if (transaction.status !== "Rejected") {
          return sum + transaction.amount;
        }
        return sum;
      }, 0);

      data.totalApproved = totalApproved;
    }

    if (supervisorBuildings && supervisorBuildings?.data.length) {
      const selectedBuilding = supervisorBuildings.data[0];
      const { bankAccount } = selectedBuilding;

      if (bankAccount) {
        data.bankAccount = {
          label: bankAccount.name,
          value: bankAccount.id
        };
      }
    }

    setInitialState(data);
  }, [supervisorBuildings]);

  const setTotal = (value) => {
    const totalApproved = value.reduce((sum, transaction) => {
      if (transaction.status.value !== "Rejected") {
        return sum + Number(transaction.amount || 0);
      }
      return sum;
    }, 0);

    ref.current.setFormState({
      total: totalApproved,
      totalApproved
    });
  };

  const onStateChange = (key, value) => {
    if (key === "transaction") {
      setTotal(value);
    }
  };

  const onChildStateChange = ({
    index, key, value, parentField, parentFieldType
  }) => {
    const formState = ref.current.getState();
    const stateKey = `${parentField}${parentFieldType}`;
    let parentFieldState = formState[stateKey] ?? {};

    if (index > -1) {
      parentFieldState = formState[parentField][index];
    }
    if (parentField === "transaction") {
      switch (key) {
        case "amountBeforeTax":
        case "tax": {
          parentFieldState[key] = value;
          const { tax, amountBeforeTax } = parentFieldState;
          if (tax && amountBeforeTax) {
            const { purchaseRate } = tax;
            const taxAmount = (Number(amountBeforeTax) * Number(purchaseRate)) / 100;
            const amount = Number(amountBeforeTax) + Number(taxAmount);
            parentFieldState.taxAmount = formatDecimalValues(taxAmount);
            parentFieldState.amount = formatDecimalValues(amount);
            setTotal(formState[parentField]);
          }
          break;
        }
        default:
          break;
      }
    }
  };

  const renderForm = () => {
    if (
      task?.objectName === dynamicObjectMap.get("PettyCashClaimObjectName") &&
      task?.currentStep === "Request for Petty Cash Claim" &&
      task?.taskType === TASK_TYPES.UPDATE
    ) {
      return (
        <DynamicFormContainer
          initialData={initialState}
          ref={ref}
          objectName={task.objectName}
          selectedId={recordData?.id}
          setIsLoading={setIsLoading}
          navigate={false}
          showButtons={false}
          layout="Create Petty Cash Claim"
          onSuccess={onSuccess}
        />
      );
    }

    if (
      task?.objectName === dynamicObjectMap.get("PettyCashClaimObjectName") &&
      task?.currentStep === "Issuance of Payment" &&
      task?.taskType === TASK_TYPES.UPDATE
    ) {
      return (
        <DynamicFormContainer
          initialData={initialState}
          ref={ref}
          objectName={task.objectName}
          selectedId={recordData?.id}
          onStateChange={onStateChange}
          onChildStateChange={onChildStateChange}
          setIsLoading={setIsLoading}
          navigate={false}
          showButtons={false}
          layout="Issuance of Payment"
          onSuccess={onSuccess}
          requiredFields={["bankAccount"]}
          readOnlyFields={["total", "totalApproved"]}
        />
      );
    }

    return null;
  };

  return <div>{renderForm()}</div>;
}

PettyCashClaimWorkflowFormContainer.propTypes = {
  task: PropTypes.object.isRequired,
  recordData: PropTypes.object.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default PettyCashClaimWorkflowFormContainer;
