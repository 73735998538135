import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import PropTypes from "prop-types";
import { useTable } from "react-table";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AlertModal } from "@/components/modals";
import { useModal } from "@/hooks";
import InterimPeriodActivityDrawer from "./InterimPeriodActivityDrawer";
import { handleDrawer } from "@/utils/modal/helpers";
import {
  getInterimPeriodModuleTableData,
  getInterimPeriodModuleTableColumns,
} from "./interimPeriodTableData";
import {
  closeAccountingPeriodMonth,
} from "@/api/finance/accountingPeriodApi";
import showToast from "@/utils/toast/helpers";

function InterimPeriodRows({ row }) {
  const navigate = useNavigate();
  const data = useMemo(() => getInterimPeriodModuleTableData(row), []);
  const columns = useMemo(() => getInterimPeriodModuleTableColumns(), []);

  const {
    getTableProps, getTableBodyProps, headerGroups, rows, prepareRow
  } = useTable({
    columns,
    data,
    onView: (id) => {
      const selectedInterimPeriod = data.find((i) => i.id === id);
    },
    onClose: (rowData) => {
      const selectedInterimModule = data.find((i) => i.id === rowData.id);
      navigate(`${selectedInterimModule.interimPeriodId}/close?moduleName=${selectedInterimModule.moduleName}`);
    },
    onReopen: (id) => {
      const selectedInterimPeriod = data.find((i) => i.id === id);
    },
  });

  return (
    <tr
      key={`InterimPeriod-${row.original.id}-SubRow`}
      style={{
        position: "relative",
        height: `${data.length * 49 + 55}px`,
      }}
    >
      <td aria-label="info-table" style={{ position: "absolute", width: "100%" }}>
        <div className="table-wrapper mt-0">
          <table className="table" {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr className="table-header-row" {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      className={`table-header-cell ${column.collapse ? "collapse" : ""}`}
                      {...column.getHeaderProps()}
                    >
                      <div className="table-header-cell-content">
                        <span>{column.render("Header")}</span>
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((tableRow) => {
                prepareRow(tableRow);
                return (
                  <tr className="table-row" {...tableRow.getRowProps()}>
                    {tableRow.cells.map((cell) => (
                      <td
                        className={`table-body-cell ${cell.column.collapse ? "collapse" : ""}`}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </td>
    </tr>
  );
}

export default function InterimPeriodTable({
  data, columns, className, title
}) {
  const tableData = useMemo(() => data, [data]);
  const tableColumns = useMemo(() => columns, [columns]);
  const navigate = useNavigate();

  const [selectedId, setSelectedId] = useState(null);
  const [interimPeriodActivity, setInterimPeriodActivity] = useState([]);
  const { isOpen, closeModal, openModal } = useModal(false);
  const queryClient = useQueryClient();

  const getRowState = useCallback(() => {
    const cellState = {};
    data?.forEach((_, index) => {
      cellState[index] = false;
    });
    return cellState;
  }, [data?.length]);

  const closeMonthMutation = useMutation(
    ({ id, number }) => closeAccountingPeriodMonth(id, number),
    {
      onError: () => {
        showToast("Could not close interim accounting period. Try again!", "error");
      },
      onSuccess: () => {
        showToast("Closed successfully", "success");
        queryClient.invalidateQueries({
          queryKey: ["accounting-period-by-id"]
        });
      },
    }
  );

  const [rowState, setRowState] = useState({ ...getRowState() });
  const updateRowState = (rowIndex, value) => {
    setRowState((prevRowState) => ({
      ...prevRowState,
      [rowIndex]: value,
    }));
  };

  const {
    getTableProps, getTableBodyProps, headerGroups, rows, prepareRow
  } = useTable({
    columns: tableColumns,
    data: tableData,
    rowState,
    updateRowState,
    onView: (id) => {
      const selectedInterimPeriod = tableData.find((i) => i.id === id);
      setInterimPeriodActivity(selectedInterimPeriod.activity);
      handleDrawer(id, navigate, false, "interimPeriodActivityDrawers");
    },
    onClose: ({ accountingId, number }) => {
      closeMonthMutation.mutate({
        id: accountingId,
        number,
      });
    },
    onReopen: (id) => {
      const selectedInterimPeriod = tableData.find((i) => i.id === id);
    },
  });

  const handleCloseModal = () => {
    setSelectedId(null);
    closeModal();
  };

  return (
    <div className={`table-wrapper ${className}`}>
      <table className="table" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr className="table-header-row" {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  className={`table-header-cell ${column.collapse ? "collapse" : ""}`}
                  {...column.getHeaderProps()}
                >
                  <div className="table-header-cell-content">
                    <span>{column.render("Header")}</span>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <>
                <tr className="table-row" {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td
                      className={`table-body-cell ${cell.column.collapse ? "collapse" : ""}`}
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </td>
                  ))}
                </tr>
                {rowState[row.original.index] ? (
                  <InterimPeriodRows key={`InterimPeriod-${row.original.index}`} row={row} />
                ) : null}
              </>
            );
          })}
        </tbody>
      </table>

      <AlertModal onClose={closeModal} isOpen={isOpen} onConfirm={handleCloseModal} />

      <InterimPeriodActivityDrawer
        title="Interim Period Activity"
        className="workflow-timeline-container"
        activities={interimPeriodActivity}
      />
    </div>
  );
}

InterimPeriodTable.propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  className: PropTypes.string,
  title: PropTypes.string,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
};

InterimPeriodTable.defaultProps = {
  className: "",
  title: null,
  onEdit: () => {},
  onDelete: () => {},
};
