import { useState, useEffect, useRef } from "react";
import { useQuery } from "@tanstack/react-query";
import { kebabCase } from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import { Loader } from "@hydra/atom/components";
import { useUser } from "@/hooks";
import { formatDate } from "@/utils/helpers";
import { BoxedContent, Header, TwoColumnList } from "@/components/common";
import { getDynamicObjectRecordById } from "@/api/dynamic/dynamicObjectNameApi";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import { DynamicFormContainer } from "@/components/dynamic";
import { QuotationListTable } from "@/components/finance";

const renderList = (items) => (
  <div className="card">
    <div className="list-container">
      {items &&
        items.map((item, index) => (
          <div key={`item-${index}`} className="col-md-3">
            {item}
          </div>
        ))}
    </div>
  </div>
);

function DetailItem({ heading, children }) {
  return (
    <div className="row">
      <div className="col-12">
        <h6 className="headline">{heading}</h6>
        {children}
      </div>
    </div>
  );
}

function Quotation() {
  const ref = useRef(null);
  const { user } = useUser();
  const { requestForQuotation: id } = useParams();
  const navigate = useNavigate();
  const [, setLoading] = useState(false);
  const [initialState, setInitialState] = useState();
  const [materialData, setMaterialData] = useState();
  const [materialItems, setMaterialItems] = useState([]);
  const [addedMaterialList, setAddedMaterialList] = useState(null);

  const { data: rfqData, isLoading } = useQuery(
    [kebabCase("RequestForQuotationObjectName"), id],
    () => getDynamicObjectRecordById(dynamicObjectMap.get("RequestForQuotationObjectName"), id)
  );

  useEffect(() => {
    if (rfqData) {
      setInitialState({
        requestForQuotation: {
          label: rfqData?.number,
          value: id,
        },
        supplier: user?.supplier ? {
          id: user.supplier.id,
          name: user.supplier.name,
          label: user.supplier.name,
          value: user.supplier.id
        } : null,
        dueDate: new Date(rfqData?.dueDate),
        isApproved: false,
        itemType: {
          label: rfqData?.itemType,
          value: rfqData?.itemType
        }
      });
    }
    setMaterialItems(rfqData?.item);
  }, [rfqData, user]);

  useEffect(() => {
    if (materialData && materialItems.length > 0) {
      const updatedArray = materialItems.map((item) => {
        if (item.id === materialData.id) {
          item.rate = materialData.rate;
          item.amount = item.quantity * materialData.rate;
          return item;
        }
        return item;
      });
      setMaterialItems(updatedArray);
    }
  }, [materialData]);

  const setSubTotal = (value) => {
    const total = value.reduce(
      (prevValue, currentValue) => Number(prevValue) + Number(currentValue.amount),
      0
    );

    ref.current.setFormValue("subtotal", total);
    ref.current.setFormValue("total", total);
  };

  const setTotalWithDiscountType = (value) => {
    const state = ref.current.getState();

    const { discount, subtotal } = state;

    if (!subtotal || !discount) {
      return;
    }

    if (value.value === "Amount") {
      const total = Number(subtotal) - Number(discount);

      ref.current.setFormValue("total", total);
      return;
    }

    if (value.value === "Percentage") {
      const total = subtotal - ((subtotal * value) / 100);
      ref.current.setFormValue("total", total);
    }
  };

  const setTotalWithDiscount = (value) => {
    const state = ref.current.getState();

    const { discountType, subtotal } = state;

    if (!subtotal || !value) {
      return;
    }

    if (discountType.value === "Amount") {
      const total = Number(subtotal) - Number(value);

      ref.current.setFormValue("total", total);
      return;
    }

    if (discountType.value === "Percentage") {
      const total = subtotal - ((subtotal * value) / 100);
      ref.current.setFormValue("total", total);
    }
  };

  useEffect(() => {
    if (addedMaterialList) {
      const formState = ref.current.getState();
      const { itemDetail } = formState;
      const item = {
        item: addedMaterialList.item,
        label: addedMaterialList.label,
        rate: addedMaterialList.rate,
        quantity: addedMaterialList.quantity,
        amount: addedMaterialList.amount,
      };

      let newValue = [];
      if (itemDetail) {
        newValue = [...itemDetail, item];
      } else {
        newValue = [item];
      }

      ref.current.setFormValue("itemDetail", newValue);
      setSubTotal(newValue);
    }
  }, [addedMaterialList]);

  const onStateChange = (key, value) => {
    if (key === "itemDetail") {
      setSubTotal(value);
    }

    if (key === "discountType") {
      setTotalWithDiscountType(value);
    }

    if (key === "discount") {
      setTotalWithDiscount(value);
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <BoxedContent className="gl-account-form">
      <Header showBreadcrumb leftContent={<h1>Submit Quotation</h1>} />

      <div className="col-md-12">
        <DetailItem>
          {renderList([
            <TwoColumnList
              data={[
                {
                  label: "RFQ Number",
                  value: rfqData?.number,
                },
                {
                  label: "Number of Items",
                  value: rfqData?.item.length,
                },
              ]}
            />,
            <TwoColumnList
              data={[
                {
                  label: "RFQ Status",
                  value: "Active",
                },
              ]}
            />,
            <TwoColumnList
              data={[
                {
                  label: "Due Date",
                  value: `${rfqData ? formatDate(new Date(rfqData?.dueDate)) : "N/A"}`,
                },
              ]}
            />,
          ])}
        </DetailItem>
      </div>
      <div>
        <QuotationListTable
          data={materialItems}
          setMaterialData={setMaterialData}
          setAddedMaterialList={setAddedMaterialList}
        />
      </div>
      <div className="col quotation-form-container">
        <DynamicFormContainer
          ref={ref}
          objectName={dynamicObjectMap.get("QuotationObjectName")}
          initialData={initialState}
          setIsLoading={setLoading}
          onSuccess={() => navigate("/facility-management/quotations")}
          onError={() => {}}
          showButtons
          navigate={false}
          onStateChange={onStateChange}
        />
      </div>
    </BoxedContent>
  );
}

export default Quotation;
