import { useEffect, useRef, useState } from "react";

import { isEmpty, kebabCase } from "lodash";
import { useQuery } from "@tanstack/react-query";
import toast from "react-hot-toast";
import { Button } from "@hydra/atom/components";
import { BoxedContent } from "@/components/common";
import { AlertModal } from "@/components/modals";
import { TableWithCheckbox } from "@/components/finance/account-receivables";
import { defaultComponents } from "@/components/dynamic/DynamicFormContainer";
import { DynamicFormContainer } from "@/components/dynamic";
import dynamicObjectMap from "@/utils/maps/dynamicObjectMap";
import { useModal } from "@/hooks";
import showToast from "@/utils/toast/helpers";
import { getExpensePaymentTableColumns } from "@/components/finance/account-receivables/tableWithCheckboxData";
import { getDynamicObjectRecords } from "@/api/dynamic/dynamicObjectNameApi";
import { calculateTax, formatDecimalValues } from "@/utils/helpers";

function HeaderRightContent({ openModal, showButton, ...rest }) {
  return (
    <defaultComponents.HeaderRightContent {...rest}>
      {showButton ? (
        <Button small bordered onClick={openModal}>
          Select payments
        </Button>
      ) : null}
    </defaultComponents.HeaderRightContent>
  );
}

function ExpenseForm() {
  const ref = useRef(null);
  const [state, setState] = useState();
  const [toastId, setToastId] = useState();
  const [paymentDetailsTableData, setPaymentDetailsTableData] = useState([]);
  const { isOpen, closeModal, openModal } = useModal(false);

  const { data: paymentData, isLoading } = useQuery(
    [
      kebabCase(dynamicObjectMap.get("PaymentObjectName")),
      "PartiallyAdjusted,Posted",
      state?.supplier?.value,
    ],
    () =>
      getDynamicObjectRecords(
        dynamicObjectMap.get("PaymentObjectName"),
        {
          paymentType: "SupplierAdvance",
          "status[in]": "PartiallyAdjusted,Posted",
          payee: state?.supplier?.value,
          queryMode: "Deep",
        },
        {
          enabled: Boolean(state?.supplier?.value),
        }
      )
  );

  const formatPayment = (payments) =>
    payments.map((item) => ({
      ...item,
      advanceAmount: Number(item?.total) - Number(item?.adjustedAmount ?? 0),
      adjustmentAmount: Number(item?.total) - Number(item?.adjustedAmount ?? 0),
      key: item.number,
      isSelected: false,
    }));

  useEffect(() => {
    if (paymentData || isLoading) {
      if (!state?.supplier?.value) return;
      if (isLoading) {
        setToastId(toast.loading("Loading Supplier Advance..."));
      }
      if (!paymentData) return;
      const paymentDetailData = formatPayment(paymentData.data);

      if (!paymentDetailData.length) {
        toast.dismiss(toastId);
        showToast("No Supplier Advance found for the given supplier!", "info");
      } else {
        toast.dismiss(toastId);
        showToast("Supplier Advance loaded!", "success");
      }
      ref.current.setFormValue("tRN", state?.supplier?.tRN);
      setPaymentDetailsTableData(paymentDetailData);
      openModal();
    }

    return () => {
      if (toastId) {
        toast.dismiss(toastId);
      }
    };
  }, [paymentData, state?.supplier?.value, isLoading]);

  const onBeforeSave = () => {
    const formState = ref.current.getState();
    if (formState.advanceAmount > (formState.total || 0)) {
      toast.error(
        "Total advance amount should be equal to or less than total amount."
      );
      return false;
    }
    return true;
  };

  const setOpenBalance = (total, advanceAmount) => {
    const diff = Number(total || 0) - Number(advanceAmount || 0);
    const openBalance = diff > 0 ? diff : 0;
    ref.current.setFormValue("openBalance", formatDecimalValues(openBalance));
  };

  const updateLineItem = (key, value) => {
    const formState = ref.current.getState();
    formState[key] = value;
    const {
      amountOfTax,
      tax: parentTax,
      expenseDetail,
      expenseDetailTable,
    } = formState;
    if (expenseDetailTable) {
      const { amount } = expenseDetailTable;
      const { taxAmount: prevTaxAmount } = expenseDetailTable;
      let taxAmount = 0;

      let principalAmountValue = 0;

      const selectedTax = parentTax;

      if (selectedTax && amountOfTax) {
        const parameters = {
          amount,
          amountOfTax,
          tax: selectedTax,
          taxAmount: "",
        };

        if (key === "amountOfTax") {
          parameters.taxAmount = prevTaxAmount || 0;
        }

        const { taxAmount: calculatedTaxAmount, principalAmount } =
          calculateTax(parameters);
        principalAmountValue = principalAmount;
        taxAmount = calculatedTaxAmount;
      }
      expenseDetailTable.amount = amount;
      expenseDetailTable.tax = selectedTax;
      expenseDetailTable.taxAmount = taxAmount;
      expenseDetailTable.totalAmount = formatDecimalValues(
        Number(principalAmountValue || amount) + Number(taxAmount)
      );
    }
    if (expenseDetail) {
      const updatedLineItem = expenseDetail?.map((rent) => {
        const { amount } = rent;
        const { tax, taxAmount: prevTaxAmount } = rent;
        let taxAmount = 0;
        let principalAmountValue = 0;

        let selectedTax = tax ?? parentTax;
        if (key === "tax") {
          selectedTax = parentTax;
        }

        if (selectedTax && amountOfTax) {
          const parameters = {
            amount,
            amountOfTax,
            tax: selectedTax,
            taxAmount: "",
          };

          if (key === "amountOfTax") {
            parameters.taxAmount = prevTaxAmount || 0;
          }

          const { taxAmount: calculatedTaxAmount, principalAmount } =
            calculateTax(parameters);

          principalAmountValue = principalAmount;
          taxAmount = calculatedTaxAmount;
        }

        return {
          ...rent,
          amount,
          tax: selectedTax,
          taxAmount,
          totalAmount: formatDecimalValues(
            Number(principalAmountValue || amount) + Number(taxAmount)
          ),
        };
      });

      ref.current.setFormValue("expenseDetail", updatedLineItem);
      ref.current.setFormValue("expenseDetailTable", expenseDetailTable);
    }
  };

  const setTaxAndTotal = (key, value) => {
    const formState = ref.current.getState();
    formState[key] = value;
    const { expenseDetail, advanceAmount } = formState;

    const subtotal = expenseDetail?.reduce(
      (total, currentValue) => total + Number(currentValue.amount),
      0
    );

    const data = {
      subtotal,
      taxAmount: "",
      total: "",
    };

    const taxValue = expenseDetail?.reduce(
      (t, currentValue) => t + Number(currentValue.taxAmount),
      0
    );
    const totalValue = expenseDetail?.reduce(
      (t, currentValue) => t + Number(currentValue.totalAmount),
      0
    );

    data.taxAmount = taxValue;
    data.total = formatDecimalValues(totalValue);
    ref.current.setFormState(data);
    setOpenBalance(totalValue, advanceAmount);
    updateLineItem(key, value);
  };

  const adjustAmountsAndSum = (arr) => {
    let totalAdjustmentAmount = 0;

    arr.forEach((item) => {
      if (item.adjustmentAmount > item.advanceAmount) {
        item.adjustmentAmount = item.advanceAmount;
      }
      totalAdjustmentAmount += Number(item.adjustmentAmount);
    });

    return {
      adjustedArray: arr,
      totalAdjustmentAmount,
    };
  };

  const onStateChange = (key, value) => {
    switch (key) {
      case "tax":
      case "expenseDetail":
      case "amountOfTax": {
        setTaxAndTotal(key, value);
        break;
      }

      case "vendor":
        if (value) {
          setState((prev) => ({ ...prev, supplier: value }));
          const vendorState = {
            tRN: value?.tRN,
            supplierBalance: value?.openingBalance ?? 0,
          };
          ref.current.setFormState(vendorState);
        } else {
          ref.current.setFormState({
            tRN: "",
            supplierBalance: 0,
          });
        }
        break;

      case "advancePayment": {
        const { total, advanceAmount } = ref.current.getState();
        const result = adjustAmountsAndSum(value);
        ref.current.setFormValue("advanceAmount", result.totalAdjustmentAmount);
        setOpenBalance(total, advanceAmount);
        break;
      }

      default:
        break;
    }
  };

  const onChildStateChange = ({
    index,
    key,
    value,
    parentField,
    parentFieldType,
  }) => {
    const formState = ref.current.getState();
    const stateKey = `${parentField}${parentFieldType}`;
    let parentFieldState = formState[stateKey] ?? {};

    if (index > -1) {
      parentFieldState = formState[parentField][index];
    }

    if (parentField === "expenseDetail") {
      switch (key) {
        case "expenseType": {
          parentFieldState[key] = value;

          if (value) {
            const { expenseAccount } = value;

            if (expenseAccount && !isEmpty(expenseAccount)) {
              parentFieldState.account = {
                label: expenseAccount.name,
                value: expenseAccount.id,
              };
            }
          }

          if (!value) {
            parentFieldState.account = null;
          }
          break;
        }
        case "quantity":
        case "rate":
        case "tax": {
          parentFieldState[key] = value;
          const { quantity, rate, tax } = parentFieldState;

          const amount = formatDecimalValues(quantity * rate);
          parentFieldState.amount = amount;

          const { amountOfTax } = formState;

          if (tax && amountOfTax) {
            const parameters = {
              amount: parentFieldState.amount,
              amountOfTax,
              tax,
              taxAmount: "",
            };
            const { taxAmount, principalAmount } = calculateTax(parameters);
            parentFieldState.taxAmount = taxAmount;
            parentFieldState.totalAmount =
              Number(principalAmount) + Number(taxAmount);
          }
          break;
        }

        default:
          break;
      }
    }
  };

  const handleConfirm = () => {
    if (paymentDetailsTableData.length) {
      const selectedPayments = paymentDetailsTableData.filter(
        (q) => q.isSelected
      );
      if (selectedPayments.length) {
        const { totalAdjustmentAmount } = adjustAmountsAndSum(selectedPayments);

        const formState = ref.current.getState();
        const { total } = formState;

        let openBalance = 0;

        if (total > 0 && totalAdjustmentAmount > 0 && totalAdjustmentAmount < total) {
          openBalance = Number(total) - Number(totalAdjustmentAmount);
        }

        ref.current.setFormState({
          advancePayment: selectedPayments.map((item) => ({
            payment: {
              label: item.number,
              value: item.id,
            },
            advanceAmount:
              Number(item?.total) - Number(item?.adjustedAmount ?? 0),
            adjustmentAmount:
              Number(item?.total) - Number(item?.adjustedAmount ?? 0),
            advanceStatus: {
              label: "Adjusted",
              value: "Adjusted"
            }
          })),
          advanceAmount: totalAdjustmentAmount,
          openBalance
        });
      }
    }
    closeModal();
  };

  return (
    <BoxedContent>
      <AlertModal
        icon="file-check-stroke-icon"
        iconClass="success"
        title="Select Payments"
        subtitle="Selected supplier has following advance payments"
        onClose={() => {
          closeModal();
        }}
        isOpen={isOpen}
        onConfirm={handleConfirm}
        size="large"
      >
        {paymentDetailsTableData.length ? (
          <TableWithCheckbox
            data={paymentDetailsTableData}
            searchKey={["number"]}
            columns={getExpensePaymentTableColumns()}
            setData={setPaymentDetailsTableData}
            allowMultiple
            selectAll
          />
        ) : null}
      </AlertModal>
      <DynamicFormContainer
        ref={ref}
        objectName={dynamicObjectMap.get("ExpenseObjectName")}
        showHeader
        showLinkedViews
        onStateChange={onStateChange}
        onBeforeSave={onBeforeSave}
        onChildStateChange={onChildStateChange}
        components={{
          HeaderRightContent: (props) =>
            HeaderRightContent({
              openModal,
              showButton: Boolean(paymentDetailsTableData.length),
              ...props,
            }),
        }}
      />
    </BoxedContent>
  );
}

export default ExpenseForm;
