import { format } from "date-fns";
import { startCase } from "lodash";
import { CustomActionDropdown } from "@/components/dynamic";
import { formatDate } from "@/utils/helpers";

export const moduleNames = ["AccountsReceivable", "AccountsPayable", "FixedAssets", "GeneralLedger"];

const prepareActions = (row, onView, onClose, onReopen) => {
  const { status } = row.original;

  const actions = [
    {
      title: "View",
      onClick: () => { onView(row.original.id); },
      icon: "eye"
    }
  ];

  if (!row.original.moduleName) {
    actions.push({
      title: "Close",
      onClick: () => { onClose(row.original); },
      icon: "edit-icon"
    });
    actions.push({
      title: "View history",
      onClick: () => { onView(row.original.id); },
      icon: "eye"
    });
    return actions;
  }

  if (status === "Closed") {
    actions.push({
      title: "Reopen",
      onClick: () => { onReopen(row.original.id); },
      icon: "edit-icon"
    });
  }

  if (status === "Open") {
    actions.push({
      title: "Close",
      onClick: () => { onClose(row.original); },
      icon: "edit-icon"
    });
  }

  return actions;
};

function ActionCell({
  row, onView, onClose, onReopen
}) {
  const actions = prepareActions(row, onView, onClose, onReopen);

  return (
    <div className="action-cell">
      <CustomActionDropdown
        actions={actions}
        trigger={(
          <div className="action-icon-trigger">
            <span className="material-icons-outlined">more_vert</span>
          </div>
        )}
        testId="InterimPeriod-Action-Button"
      />
    </div>
  );
}

function ViewInterimPeriodModuleCell({
  row, rowState, updateRowState
}) {
  const updateState = () => {
    updateRowState(row.original.index, !rowState[row.original.index]);
  };

  return (
    <button className="btn-toggle" type="button" onClick={updateState}>
      {rowState[row.original.index] ? (
        <span className="expand-more material-icons">expand_less</span>
      ) : (
        <span className="expand-more material-icons">expand_more</span>
      )}
    </button>
  );
}

export const getInterimPeriodTableColumns = () => [
  {
    Header: "Number",
    accessor: "number",
  },
  {
    Header: "Start Date",
    accessor: "startDate",
  },
  {
    Header: "End Date",
    accessor: "endDate",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Module Status",
    accessor: "index",
    Cell: ViewInterimPeriodModuleCell
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: ActionCell,
    collapse: true
  }
];

export const getInterimPeriodTableData = (data) => data.map((interimPeriod, index) => ({
  index,
  id: interimPeriod.number,
  accountingId: interimPeriod.accountingId,
  number: interimPeriod.number,
  startDate: formatDate(new Date(interimPeriod.startDate)),
  endDate: formatDate(new Date(interimPeriod.endDate)),
  status: interimPeriod.status,
  closes: interimPeriod.closes,
  activity: interimPeriod.closes.map((close) => ({
    name: `${close.status} ${startCase(close.moduleName)}:`,
    title: `${startCase(close.moduleName)} ${close.status}ed at ${format(new Date(close.approvedAt), "hh:mm")} on ${formatDate(new Date(close.approvedAt))}`,
    tags:
      close.status === "Open" ?
        [{ text: startCase(close.status), variant: "success" }] :
        [{ text: startCase(close.status), variant: "primary" }],
  }))
}));

export const getInterimPeriodModuleTableData = (row) => moduleNames.map((moduleName, index) => {
  const status = row.original.closes.find((item) => item.moduleName === moduleName)?.status;
  const moduleRow = {
    id: index,
    interimPeriodId: row.original.number,
    moduleName,
    moduleLabel: startCase(moduleName),
    status: status || "Open",
    approvedAt: "",
    action: ""
  };

  const moduleCloses = row.original.closes.filter((c) => c.moduleName === moduleName);
  const lastClose = moduleCloses[-1];

  if (lastClose) {
    moduleRow.status = lastClose.status;
    moduleRow.approvedAt = `${format(new Date(lastClose.approvedAt), "hh:mm")} ${formatDate(
      new Date(lastClose.approvedAt)
    )}`;
  }

  return moduleRow;
});

export const getInterimPeriodModuleTableColumns = () => [
  {
    Header: "Module",
    accessor: "moduleLabel",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Approved At",
    accessor: "approvedAt",
  },
  {
    Header: "Action",
    accessor: "action",
    Cell: ActionCell,
    collapse: true
  }
];
