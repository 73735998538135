import { useState, useMemo, useEffect } from "react";
import {
  useTable, useExpanded, useFilters, useSortBy
} from "react-table";
import { useNavigate } from "react-router-dom";
import qs from "qs";
import Skeleton from "react-loading-skeleton";
import { kebabCase } from "lodash";

import { SortableArrows } from "@/components/common";
import { AlertModal } from "@/components/modals";
import { useModal } from "@/hooks";

function Table({
  columns: userColumns,
  data,
  handleDelete,
  expandAllRowsByDefault,
  objectName
}) {
  const [selectedId, setSelectedId] = useState(null);
  const { isOpen, closeModal, openModal } = useModal(false);
  const navigate = useNavigate();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    toggleAllRowsExpanded
  } = useTable(
    {
      objectName,
      columns: userColumns,
      data,
      onDelete: (id) => {
        openModal();
        setSelectedId(id);
      },
      onView: (id) => {
        navigate(`/finance/${kebabCase(objectName)}/details/${id}`);
      },
      onEdit: (id) => {
        navigate(`/finance/${kebabCase(objectName)}/${id}`);
      },
      onCreateChild: (id) => {
        const searchParams = qs.stringify({
          isCreatingChild: true,
          parentId: id,
        });
        navigate(`/finance/${kebabCase(objectName)}/new?${searchParams}`);
      },
    },
    useFilters,
    useSortBy,
    useExpanded
  );

  useEffect(() => {

    if (expandAllRowsByDefault) {
      toggleAllRowsExpanded();
    }
  }, [expandAllRowsByDefault]);

  return (
    <>
      <table className="table" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr
              className="table-header-row"
              {...headerGroup.getHeaderGroupProps()}
            >
              {headerGroup.headers.map((column) => (
                <th
                  className={`table-header-cell ${
                    column.collapse ? "collapse" : ""
                  }`}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  <div className="table-header-cell-content">
                    {column.render("Header")}
                    {column.defaultCanSort ? (
                      <SortableArrows
                        isSorted={column.isSorted}
                        isSortedDesc={column.isSortedDesc}
                      />
                    ) : null}
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr
                className="table-row"
                {...row.getRowProps()}
                onClick={() => row.toggleRowExpanded(!row.isExpanded)}
              >
                {row.cells.map((cell) => (
                  <td
                    className={`table-body-cell ${
                      cell.column.collapse ? "collapse" : ""
                    }`}
                    {...cell.getCellProps()}
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>

      <AlertModal
        onClose={closeModal}
        isOpen={isOpen}
        onConfirm={() => {
          handleDelete(selectedId);
          closeModal();
        }}
      />
    </>
  );
}

function ExpandingRowsTable({
  tableColumns,
  data,
  isLoading,
  handleDelete,
  expandAllRowsByDefault,
  objectName
}) {
  const columns = useMemo(
    () => (isLoading ?
      tableColumns.map((column) => ({
        ...column,
        Cell: Skeleton,
      })) :
      tableColumns),
    [isLoading, tableColumns]
  );

  const rows = useMemo(() => data, [data]);

  return (
    <div className="table-wrapper">
      <Table
        isLoading={isLoading}
        columns={columns}
        data={rows}
        handleDelete={handleDelete}
        expandAllRowsByDefault={expandAllRowsByDefault}
        objectName={objectName}
      />
    </div>
  );
}

export default ExpandingRowsTable;
