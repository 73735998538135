import { useNavigate } from "react-router-dom";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { Button, Input } from "@hydra/atom/components";
import { useState } from "react";
import { useSelector } from "react-redux";
import {
  BoxedContent, Header, NoDataFound, SvgIcon, HeaderLeftContent
} from "@/components/common";
import { ExpandingRowsTable } from "@/components/finance";
import {
  getTaxRuleTableColumns,
} from "@/components/finance/tax-rule/taxRuleTableData";
import { getTaxRules, deleteTaxRule } from "@/api/finance/taxRuleApi";
import { skeletonLoadingRows } from "@/utils/helpers";
import { buildTree } from "@/utils/finance/helpers";
import showToast from "@/utils/toast/helpers";
import { selectPermission } from "@/store/userSlice";
import staticObjectMap from "@/utils/maps/staticObjectMap";

function TaxGroup() {
  const navigate = useNavigate();
  const { data, isLoading } = useQuery(["tax-rule"], getTaxRules);
  const [filterText, setFilterText] = useState("");
  const hasPermission = useSelector(selectPermission({ parent: "Model", scope: staticObjectMap.get("TaxRuleObjectName"), action: "Insert" }));

  const queryClient = useQueryClient();
  const deleteMutation = useMutation(
    deleteTaxRule,
    {
      onError: () => {
        showToast("Could not delete. Try again!", "error");

      },
      onSuccess: () => {
        showToast("Deleted successfully", "success");

        queryClient.invalidateQueries({
          queryKey: ["tax-rule"]
        });
      },
    }
  );

  const getFilteredData = (rawData) => {
    if (!rawData) return [];
    return rawData.filter((item) =>
      item.name.toLowerCase().includes(filterText.toLowerCase())
    );
  };

  const renderTable = () => {
    const filteredData = getFilteredData(data?.data);
    if (!isLoading && (!data || data?.data?.length === 0)) {
      return (
        <NoDataFound
          title="No tax has been added"
          buttonText="Add tax"
          onClick={() => navigate("/finance/tax-rule/new")}
        />
      );
    }
    return (
      <ExpandingRowsTable
        objectName="TaxRule"
        isLoading={isLoading}
        tableColumns={getTaxRuleTableColumns(data?.data || [])}
        data={filteredData ? buildTree(filteredData) : skeletonLoadingRows(5)}
        handleDelete={(id) => deleteMutation.mutate(id)}
      />
    );
  };

  return (
    <BoxedContent>
      <Header
        showBreadcrumb
        leftContent={<HeaderLeftContent title="Tax" icon="clipboard-plus-stroke-icon" />}
        rightContent={(
          <div className="tax-rule-left-header">
            <div>
              <Input
                className="input-height-fix"
                value={filterText}
                onChange={(e) => { setFilterText(e); }}
                placeholder="Search by name"
              />
            </div>
            <div>
              {hasPermission && (
              <Button medium onClick={() => navigate("/finance/tax-rule/new")} className="btn-with-icon">
                <Button.Prepend>
                  <SvgIcon icon="plus-icon" />
                </Button.Prepend>
                Tax
              </Button>
              )}
            </div>
          </div>
        )}
      />
      {renderTable()}
    </BoxedContent>
  );
}

export default TaxGroup;
